import React, { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import loginSchema from '../../utils/ValidationSchema/loginValidation';
import TextInput from '../common/InputFields/TextInput';
import axios from 'axios';
import {
  checkLogin,
  // checkValidateToken
} from '../../services/LoginServices';
import { navigate } from 'gatsby';
import Checkbox from '../common/InputFields/Checkbox';
import {
  createWooCommerceCustomer,
  getUserById,
  getWooCommerceCustomer,
} from '../../services/woocommerceServices/Customer';
import { userCartItems } from '../../store/cartSlice';
import { useDispatch } from 'react-redux';
import { checkCouponValidity, getQueryString } from '../../utils/helper';
import Button from '../common/Buttons/Button';
import ErrorMsg from '../common/ErrorMsg';
import { toast } from 'react-toastify';
import Link from '../Link';
import { useLocation } from '@reach/router';

interface Props {
  goToRegister: Function;
}

const LoginContent: FC<Props> = ({ goToRegister }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      lusername: '',
      lpassword: '',
      rememberMeLogin: false,
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      const { lusername, lpassword, rememberMeLogin } = values;
      let data = {
        username: lusername,
        password: lpassword,
      };

      try {
        setLoading(true);
        const resp: any = await checkLogin(data);
        // const resp: any = await checkCustomLogin(data);
        // const validateTokenResp: any = await checkValidateToken(data);

        const userData = resp.data.data;
        if (userData.token) {
          localStorage.setItem('user', JSON.stringify(userData));

          const user: any = await getWooCommerceCustomer(userData.user_email);

          if (!user) {
            toast.error('User not found!');
            setLoading(false);
            return;
          }

          const metadatas: any[] = user.data?.[0]?.meta_data;
          if (metadatas?.length > 0) {
            const idx = metadatas.findIndex((i) => i.key === 'cart_items');

            if (idx > -1 && metadatas?.[idx]?.value?.length > 0) {
              dispatch(
                userCartItems({
                  items: metadatas[idx].value,
                  userId: user.data?.[0]?.id,
                })
              );
            }
          }

          const redirectUrl = getQueryString('r');

          if (!redirectUrl) {
            // navigate(-1);
            navigate(`/my-account` + location.search);
          } else {
            navigate(`/${redirectUrl}` + location.search);
          }
        }
        if (rememberMeLogin === true) {
          localStorage.setItem('rememberMeLogin', 'yes');
        }
        setLoading(false);
      } catch (error: any) {
        // console.error("There was an error creating the login:", error);
        toast.error(error.msg);
        setLoading(false);
      }
    },
  });

  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] lg:mt-[60px] mt-[20px] lg:mb-[80px] mb-[30px] contain log-ui">
      <h1 className="md:text-center text-left text-[40px]  md:mb-5 mb-2 text-black font-sofiaSemibold font-semiBold">
        Login
      </h1>
      <div className="bg-[#F4F4F4] lg:p-[25px] mx-auto xl:w-[908px] md:max-w-[908px] md:w-[90%] w-full rounded-[21px]">
        <div className="w-full mx-auto md:px-[20px] py-[30px] px-[15px]">
          <form name="loginForm" onSubmit={formik.handleSubmit} noValidate>
            <div className="w-full">
              <div className="w-full flex flex-col relative">
                <TextInput
                  type={'text'}
                  name={'lusername'}
                  id={'lusername'}
                  autoComplete={'lusername'}
                  label={'Username or email address'}
                  placeholder={'Username or email address'}
                  className={'w-full input'}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lusername}
                />
                {formik.errors.lusername && formik?.touched.lusername ? (
                  <ErrorMsg value={formik.errors.lusername} />
                ) : null}
              </div>
            </div>
            <div className="w-full mt-4">
              <div className="w-full flex flex-col relative">
                <TextInput
                  type={'password'}
                  name={'lpassword'}
                  id={'lpassword'}
                  autoComplete={'lpassword'}
                  label={'Password'}
                  placeholder={'Enter your Password'}
                  className={'w-full input'}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lpassword}
                />
                {formik.errors.lpassword && formik?.touched.lpassword ? (
                  <ErrorMsg value={formik.errors.lpassword} />
                ) : null}
              </div>
            </div>
            <div>
              <Button
                type="submit"
                text="Login"
                className="submit-btn bg-black hover:bg-primary text-[20px] font-sofiaMedium font-medium w-full  py-3 text-white rounded-[35px] mt-8 mb-3 transition-all ease-in"
                loading={loading}
                disabled={loading}
              />
              <div className="md:text-center text-left ">
                <Checkbox
                  id="rememberMeLogin"
                  name="rememberMeLogin"
                  label="Remember Me"
                  checked={formik.values.rememberMeLogin}
                  onChange={formik.handleChange}
                  className="peer cursor-pointer  lg:mt-[-10px] mt-[-2px] relative h-4 w-4  border border-black transition-all appearance-none rounded-[5px]"
                />
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <Link
                preserveQueryParams={true}
                to="/forgot-password"
                className="underline w-fit text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18 text-left"
              >
                Forgot password
              </Link>
              {/* <a
								href="/forgot-password"
								className="underline w-fit text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18 text-left"
							>
								Forgot password
							</a> */}
              {/* <Link to="#" className="underline w-fit text-right text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18"
							onClick={(e) => {
								e.preventDefault;
								goToRegister();
							}}
							>
								Sign Up
							</Link> */}
              <Link
                href="#"
                className="underline w-fit text-right text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18"
                onClick={(e: any) => {
                  e.preventDefault;
                  goToRegister();
                }}
              >
                Sign Up
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginContent;
