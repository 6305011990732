import React, { useEffect } from 'react';
import { ArrowLeft, ArrowRight } from '../Svg';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

const Pagination = (props: any) => {
  const location = useLocation();
  const totalPages = props.page;
  const currentPage = props.currentPage;

  // Function to create pagination range
  const createPageRange = () => {
    const range = [];
    const maxPagesToShow = 3;

    if (totalPages <= maxPagesToShow) {
      // If total pages are less than or equal to the max pages to show, display all
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      // Always show the first and last few pages
      if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
        // Near the start
        for (let i = 1; i <= maxPagesToShow; i++) {
          range.push(i);
        }
        range.push('...');
        range.push(totalPages);
      } else if (currentPage >= totalPages - Math.floor(maxPagesToShow / 2)) {
        // Near the end
        range.push(1);
        range.push('...');
        for (let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          range.push(i);
        }
      } else {
        // In the middle
        range.push(1);
        range.push('...');
        for (
          let i = currentPage - Math.floor(maxPagesToShow / 2);
          i <= currentPage + Math.floor(maxPagesToShow / 2);
          i++
        ) {
          if (i > 0 && i <= totalPages) {
            range.push(i);
          }
        }
        range.push('...');
        range.push(totalPages);
      }
    }

    return range;
  };

  const pageRange = createPageRange();

  const PageShiftHandler = (type: string) => {
    if (type === 'next' && currentPage < totalPages) {
      let url = location.search;
      // Remove 'page' parameter if it exists
      url = url
        .replace(/([?&])page=\d+(&|$)/g, (match, p1, p2) =>
          p1 === '?' && p2 === '&' ? '?' : p1
        )
        .replace(/[?&]$/, ''); // Remove trailing '?' or '&'

      // Append the new page parameter
      navigate(`${url}${url ? '&' : '?'}page=${currentPage + 1}`);
      props.setCurrentPage(currentPage + 1);
      return;
    }

    if (type === 'previous' && currentPage > 1) {
      let url = location.search;

      // Remove 'page' parameter if it exists
      url = url
        .replace(/([?&])page=\d+(&|$)/g, (match, p1, p2) =>
          p1 === '?' && p2 === '&' ? '?' : p1
        )
        .replace(/[?&]$/, ''); // Remove trailing '?' or '&'

      // Calculate the new page number
      const newPage = currentPage - 1;

      // Check if the new page number is 1
      if (newPage === 1) {
        // If it is, remove the page parameter completely
        navigate(url); // Navigate without any page parameter
      } else {
        // Otherwise, append the new page parameter
        navigate(`${url}${url ? '&' : '?'}page=${newPage}`);
      }

      props.setCurrentPage(newPage);
    }
  };

  return (
    <nav aria-label="Page navigation example" className="pt-3">
      <ul className="flex justify-center items-center gap-2">
        <li
          className={`page-item ${
            currentPage > 1 ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={() => {
            currentPage > 1 && PageShiftHandler('previous');
          }}
        >
          <div
            className={`${currentPage > 1 ? 'text-black' : 'text-[#a2a2a2]'}`}
          >
            <ArrowLeft />
          </div>
        </li>
        {pageRange.map((pageNumber, index) => {
          return (
            <li
              className={`flex justify-start items-center cursor-pointer rounded-full md:w-[43px] w-[26px] md:h-[43px] h-[26px] md:text-[14px] text-[13px] font-sofiaLight font-light  ${
                pageNumber === currentPage ? 'bg-primary' : ''
              }`}
              key={index}
              onClick={() => {
                if (pageNumber !== '...') {
                  let url = location.search;
                  const newPageParam = `page=${pageNumber}`;

                  // Check if the 'page' parameter exists in the string
                  if (url) {
                    // Remove all occurrences of the 'page' parameter
                    url = url.replace(/([?&])page=\d+(&|$)/g, (match, p1, p2) =>
                      p1 === '?' && p2 === '&' ? '?' : p1
                    );

                    // Remove any trailing '?' or '&' if it's left at the end
                    url = url.replace(/[?&]$/, '');

                    // Check if the new page number is 1
                    if (pageNumber === 1) {
                      // If it is, navigate without the page parameter
                      navigate(url); // Navigate without any page parameter
                    } else {
                      // Otherwise, append the new page parameter
                      navigate(`${url}${url ? '&' : '?'}${newPageParam}`);
                    }
                  } else {
                    // If no parameters exist, initialize with the new page parameter
                    navigate(`?${newPageParam}`);
                  }

                  props.setCurrentPage(pageNumber);
                }
              }}
            >
              <p
                className={`page-link text-center w-full ${
                  pageNumber === currentPage ? 'text-white' : ''
                }`}
              >
                {pageNumber}
              </p>
            </li>
          );
        })}
        <li
          className={`page-item ${
            currentPage < totalPages ? 'cursor-pointer' : 'cursor-not-allowed'
          }`}
          onClick={() => {
            currentPage < totalPages && PageShiftHandler('next');
          }}
        >
          <div
            className={`${
              currentPage < totalPages ? 'text-black' : 'text-[#a2a2a2]'
            }`}
          >
            <ArrowRight />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
