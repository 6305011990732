import axios from 'axios';
import wooCommerce from '../../utils/woocommerce';
import { useStaticQuery, graphql } from 'gatsby';

export const getAllProducts = async (page: any, order_by: any) => {
  let filter;
  let param;
  if (order_by == 'price-desc') {
    filter = 'price';
    param = {
      params: {
        page: page,
        per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
        orderby: filter,
        order: 'desc',
        status: 'publish',
      },
    };
  } else if (order_by == 'price') {
    filter = 'price';
    param = {
      params: {
        page: page,
        per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
        orderby: filter,
        order: 'asc',
        status: 'publish',
      },
    };
  } else if (order_by == 'title') {
    filter = 'title';
    param = {
      params: {
        page: page,
        per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
        orderby: filter,
        order: 'asc',
        status: 'publish',
      },
    };
  } else if (order_by == 'title-desc') {
    filter = 'title';
    param = {
      params: {
        page: page,
        per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
        orderby: filter,
        order: 'desc',
        status: 'publish',
      },
    };
  } else if (order_by == 'date') {
    filter = 'date';
    param = {
      params: {
        page: page,
        per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
        orderby: filter,
        order: 'desc',
        status: 'publish',
      },
    };
  } else {
    filter = 'date';
    param = {
      params: {
        page: page,
        per_page: process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE,
        orderby: filter,
        order: 'asc',
        status: 'publish',
      },
    };
  }
  try {
    const response = await wooCommerce.get('products', param);
    let data = response.data;

    const filteredProducts = data.filter(
      (product: { categories: any[] }) =>
        !product.categories.some(
          (category) =>
            category.slug === 'discounted-upsell-products' ||
            category.slug === 'dummy-products'
        )
    );
    data = filteredProducts;

    return {
      data: data,
      total: response.headers['x-wp-total'],
      page: response.headers['x-wp-totalpages'],
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // console.error("Error fetching products:", error.message);
    } else {
      // console.error("Unknown error:", error);
    }
    return [];
  }
};
