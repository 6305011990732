import React from 'react';
import { PaymentPageLoaderIcon } from '../Svg';

const PaymentPageLoader = () => {
  return (
    <div
      id="loader"
      className="loader-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[60]"
    >
      {/* <div className="spinner-border spinner-loader animate-spin inline-block w-8 h-8 border-4 rounded-full text-black"></div> */}
      <PaymentPageLoaderIcon />
    </div>
  );
};

export default PaymentPageLoader;
