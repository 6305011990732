import React, { FC, useState, useEffect } from 'react';
import { Crumb } from '../../types';
import AboutBanner from '../layout/Banner/AboutBanner';
import Breadcrumb from '../common/Breadcrumb';
import Layout from '../layout/Layout';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { subscribe } from '../../services/KlaviyoServices';
import { toast } from 'react-toastify';

interface Props {
  pageData: any;
}

const AboutContent: FC<Props> = ({ pageData }) => {
  const [loading, setLoading] = useState(false);
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const bottomBanner = pageData?.aboutPage?.bottomBanner;
  const whyChoose = pageData?.aboutPage?.whyChoose;
  const featureImageUrl = pageData?.featuredImage?.node?.sourceUrl;
  const altText = pageData?.featuredImage?.node?.altText;
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  useEffect(() => {
    setCrumbs([{ label: 'About', link: null }]);
  }, []);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      // console.log("Validated Email:", email);
      setEmailError('');
      // Proceed with submitting the email
      try {
        setLoading(true);
        const result = await subscribe(email, '', 'S27Kry');
        if (!result.user && result.success) {
          toast.success('You are subscribed. ');
        } else if (result.user && !result.success) {
          throw new Error('You are already subscribed. ');
        } else {
          throw new Error('Something went wrong.');
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
      }
      setEmail('');
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  return (
    <Layout>
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain">
        <AboutBanner pageData={pageData} />
        <Breadcrumb crumbs={crumbs} />
        <div className="about_content_wrapper flex md:flex-row flex-col items-center mb-[60px] lg:mb-[90px]">
          <div
            className="content_wrapper_div w-full sm:pr-0 md:pr-[40px]"
            dangerouslySetInnerHTML={{ __html: pageData.content }}
          ></div>
          <div className="feature_img_div w-full my-auto text-center">
            <LazyLoadImage effect="blur" src={featureImageUrl} alt={altText} />
          </div>
        </div>
        <div className="why_choose_content_wrapper flex md:flex-row  justify-between items-center xl:mb-[90px] mb-[30px] xl:flex-row flex-col-reverse">
          <div className="feature_img_div w-full my-auto">
            <LazyLoadImage
              effect="blur"
              src={whyChoose?.image?.node?.sourceUrl}
              alt={whyChoose?.image?.node?.altText}
            />
          </div>
          <div
            className="content_wrapper_div w-full sm:pl-0 md:pl-[40px]"
            dangerouslySetInnerHTML={{
              __html: whyChoose?.description,
            }}
          ></div>
        </div>
        <div className="Bottom_banner_wrapper flex md:flex-row flex-col justify-between items-center bg-[#BA322F] rounded-[21px] px-[30px] xl:py-[30px] pt-[10px] pb-[20px] text-white  xl:mb-[90px] mb-[60px]">
          <div
            className="banner_des_div"
            dangerouslySetInnerHTML={{
              __html: bottomBanner?.description,
            }}
          ></div>
          <div className="banner_newsletter_div relative">
            <form
              name="aboutForm"
              onSubmit={handleSubmit}
              className="flex justify-between mb-3 rounded-[35px]  subcribe-form  overflow-visible"
            >
              <div className="relative newsletter_input_div">
                <input
                  id="about_us"
                  className="shadow-inner text-black px-[34px] placeholder:text-[#383838] text-sm bg-white focus:border-0 focus:outline-0"
                  placeholder="Enter your email address"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                  <p className="subscribe-error-msg text-red-500 absolute border border-[#ba322f] border-2 rounded-[10px] bg-white text-black p-[10px]">
                    {emailError}
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="shadow-lg bg-black text-white font-bold text-sm px-[20px]"
              >
                {loading ? 'Processing' : 'Subscribe'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutContent;
