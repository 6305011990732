import React, { useState, FC, useEffect } from 'react';
import EditAddress from './editAddress';
import { JWTChecker } from '../../utils/helper';
import {
  getUserById,
  getWooCommerceCustomer,
  updateAccountDetails,
} from '../../services/woocommerceServices/Customer';
import { toast } from 'react-toastify';
import PageLoader from '../common/Loader/PageLoader';
import useJWTChecker from '../../utils/Custom-hook/useJWTChecker';
import AddressInfoCard from './AddressInfoCard';
import { AddSvg, EditSvg } from '../common/Svg';
import { useSelector } from 'react-redux';
import { rootReducer } from '../../store';

const AddressComponent = () => {
  const { isLoggedIn, userId, email } = useJWTChecker();
  const [isShowEditAddress, setIsShowEditAddress] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [addressInfo, setAddressInfo] = useState<any>('');
  const [editAddressType, setEditAddressType] = useState<string>('');
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const { refreshUI } = useSelector((state: rootReducer) => state.cart);

  useEffect(() => {
    setIsShowEditAddress(false);
  }, [refreshUI]);

  useEffect(() => {
    isLoggedIn && email && userId && fetchAddress();
  }, [isLoggedIn, userId, email]);

  const fetchAddress = async () => {
    if (isLoggedIn && email && userId) {
      let userInfo: any;
      let getStoredUserDetails =
        localStorage.getItem('userAccountDetails') || null;

      const localUserDetails = getStoredUserDetails
        ? JSON.parse(getStoredUserDetails)
        : false;

      if (localUserDetails) {
        userInfo = localUserDetails;
      } else {
        setLoader(true);
        const fetchUserInfo: any = await getUserById(userId);

        // localStorage.setItem(
        //   "userAccountDetails",
        //   JSON.stringify(fetchUserInfo?.data)
        // );
        userInfo = fetchUserInfo?.data;
      }

      if (userInfo) {
        setLoader(false);
        setAddressInfo(userInfo);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  const onPressEdit = async (updatedData: any) => {
    setButtonLoader(true);

    let userInfo: any;
    let getStoredUserDetails =
      localStorage.getItem('userAccountDetails') || null;

    const localUserDetails = getStoredUserDetails
      ? JSON.parse(getStoredUserDetails)
      : false;

    if (localUserDetails) {
      userInfo = localUserDetails;
    } else if (isLoggedIn && email) {
      try {
        let fetchInfo: any = await getWooCommerceCustomer(email);
        userInfo = fetchInfo?.data ? fetchInfo?.data?.[0] : '';
      } catch (err) {
        // console.log("err", err);
        userInfo = '';
      }
    }

    if (userId) {
      let payload =
        editAddressType == 'Billing'
          ? {
              ...userInfo,
              billing: updatedData,
            }
          : {
              ...userInfo,
              shipping: updatedData,
            };

      if (payload?.billing?.email == '') {
        setIsShowEditAddress(false);
        setButtonLoader(false);
        toast.error('Please fill billing address details first');
        return;
      }

      try {
        const getUpdateResponse: any = await updateAccountDetails(
          userId,
          payload
        );

        if (getUpdateResponse?.data) {
          // setButtonLoader(false);
          setAddressInfo(getUpdateResponse?.data);
          localStorage.setItem(
            'userAccountDetails',
            JSON.stringify(getUpdateResponse?.data)
          );
        } else {
          toast.error('error, please try again later!');
          setIsShowEditAddress(false);
          setButtonLoader(false);
        }
      } catch (err) {
        // console.log("err", err);
        toast.error('error, please try again later!');
        setIsShowEditAddress(false);
        setButtonLoader(false);
      }
    }
    setIsShowEditAddress(false);
    setButtonLoader(false);
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <>
          {!isShowEditAddress && (
            <>
              <div className="flex lg:flex-row flex-col gap-3">
                {/* billing address */}
                <div className="w-full border border-b rounded-[12px]">
                  <div className="py-3 px-3 flex justify-between items-center bg-bg-grey  rounded-t-[12px] border-b">
                    <h2 className="text-[20px] font-sofiaSemibold font-semiBold text-black  text-left">
                      Billing Addresses
                    </h2>
                    <div className="flex space-x-2">
                      {addressInfo?.billing?.address_1 == '' && (
                        <button
                          onClick={() => {
                            setEditAddressType('Billing');
                            setIsShowEditAddress(true);
                          }}
                          className="bg-blue-500 text-black px-4 py-2 rounded-md hover:bg-blue-600"
                        >
                          <AddSvg />
                        </button>
                      )}

                      {addressInfo?.billing &&
                        (addressInfo?.billing?.address_1 ||
                          addressInfo?.billing?.address_2) && (
                          <button
                            onClick={() => {
                              setEditAddressType('Billing');
                              setIsShowEditAddress(true);
                            }}
                            className="text-blue-200 hover:text-blue-600"
                          >
                            <EditSvg />
                          </button>
                        )}
                    </div>
                  </div>

                  <div className="">
                    {addressInfo?.billing &&
                      addressInfo?.billing?.address_1 && (
                        <AddressInfoCard
                          addressDetails={addressInfo?.billing}
                        />
                      )}
                  </div>
                </div>
                {/* billing address */}
                {/* shipping address */}
                <div className="w-full  border rounded-[12px]">
                  <div className="py-3 px-3 flex justify-between items-center bg-bg-grey rounded-t-[12px] border-b">
                    <h2 className="text-[20px] font-sofiaSemibold font-semiBold text-black  text-left">
                      Shipping Addresses
                    </h2>

                    <div className="flex space-x-2">
                      {addressInfo?.shipping?.address_1 == '' && (
                        <button
                          onClick={() => {
                            setEditAddressType('Shipping');
                            setIsShowEditAddress(true);
                          }}
                          className="bg-blue-500 text-black px-4 py-2 rounded-md hover:bg-blue-600"
                        >
                          <AddSvg />
                        </button>
                      )}

                      {addressInfo?.shipping &&
                        (addressInfo?.shipping?.address_1 ||
                          addressInfo?.shipping?.address_2) && (
                          <button
                            onClick={() => {
                              setEditAddressType('Shipping');
                              setIsShowEditAddress(true);
                            }}
                            className="text-blue-200 hover:text-blue-600"
                          >
                            <EditSvg />
                          </button>
                        )}
                    </div>
                  </div>
                  <div className="">
                    {addressInfo?.shipping &&
                      addressInfo?.shipping?.address_1 && (
                        <AddressInfoCard
                          addressDetails={addressInfo?.shipping}
                        />
                      )}
                  </div>
                </div>
                {/* shipping address */}
              </div>
            </>
          )}
        </>
      )}

      {/* -------------------- EDIT ADDRESS COMPONENT -------------------- */}
      {isShowEditAddress && (
        <EditAddress
          buttonLoading={buttonLoader}
          editAddressType={editAddressType}
          editAddressData={addressInfo}
          onPressSaveAddress={(data: any) => {
            onPressEdit(data);
          }}
          onPressCancelEdit={() => {
            setIsShowEditAddress(false);
          }}
        />
      )}
    </>
  );
};

export default AddressComponent;
