import React, { FC, useState, useEffect, useMemo, Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getAllProducts } from '../../services/woocommerceServices/Shop';
import { FILTER_CONTENT } from '../../utils/Constants/FilterContent';
import { Crumb, Product } from '../../types';
import Pagination from '../common/Pagination/Pagination';
import PageLoader from '../common/Loader/PageLoader';
import Breadcrumb from '../common/Breadcrumb';
import Card from './Card';
import CatDet from '../../assets/images/cat-details.png';
import proImg from '../../assets/images/banner-pr.png';
import proImgBg from '../../assets/images/pro-img.png';
import proImgBgMob from '../../assets/images/pro-ban-mob.png';
import person from '../../images/category-images/shutterstock_585077581.png';
import CatDetMob from '../../assets/images/cat-details-mob.png';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getQueryString } from '../../utils/helper';

interface ProductListProps {
  pageContext: any;
  categoryId: string;
  categoryName: string;
}

interface Props {
  pageData: any;
  products: Product[];
}

const ShopList: FC<Props> = ({ pageData, products }) => {
  const ITEMS_PER_PAGE = Number(process.env.GATSBY_GRAPHQL_RECORD_PER_PAGE);
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const pageNumber = useMemo(() => Number(getQueryString('page')) || 1, []); // Ensure consistent pageNumber between SSR and client
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const productList = products.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  const [records, setRecords] = useState<Product[]>(productList);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(FILTER_CONTENT[0].value);

  const totalPages = Math.ceil(products.length / ITEMS_PER_PAGE);

  useEffect(() => {
    setCrumbs([{ label: 'All Peptides', link: null }]);
  }, []);

  useEffect(() => {
    setRecords(products.slice(startIndex, startIndex + ITEMS_PER_PAGE));
  }, [currentPage, products]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const productsData: any = await getAllProducts(currentPage, sort);
        setRecords(productsData.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    if (sort !== 'menu_order') fetchProducts();
  }, [currentPage, sort]);

  const filterHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(e.target.value);
    setCurrentPage(1); // Reset to page 1 on sort change
  };

  // Render SEO-friendly content first
  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] md:pb-[50px] pb-[30px] contain min-h-[50vh]">
      {/* Category List Banner */}
      <div className="list-ban relative xl:min-h-[200px] lg:min-h-[96px] md:min-h-[96px] flex flex-col justify-center ">
        {/* <div
          className="md:w-[50%] w-[80%] lg:pl-[30px] pl-[18px] z-10 relative"
          dangerouslySetInnerHTML={{
            __html: pageData?.allPeptideData?.headerBannerText,
          }}
        /> */}
        <div className="md:w-[50%] w-[60%] lg:pl-[30px] pl-[18px] z-10 relative ">
          <h2
            dangerouslySetInnerHTML={{
              __html: pageData?.allPeptideData?.headerBannerText,
            }}
          />
        </div>
        <div className="top-0 lg:right-0 right-0 z-[5] xl:w-[77%] w-[100%] h-full absolute">
          <picture>
            {/* Desktop image */}
            <source
              media="(min-width: 768px)"
              srcSet={
                pageData?.allPeptideData?.headerBannerImageDesktop1000X452?.node
                  ?.sourceUrl || CatDet
              }
            />
            {/* Mobile image */}
            <source
              media="(max-width: 767px)"
              srcSet={
                pageData?.allPeptideData?.headerBannerImageMobile444X360?.node
                  ?.sourceUrl || CatDetMob
              }
            />

            {/* Fallback image (desktop if none of the above match) */}
            <img
              src={
                pageData?.allPeptideData?.headerBannerImageDesktop1000X452?.node
                  ?.sourceUrl || CatDet
              }
              alt={
                pageData?.allPeptideData?.headerBannerImageDesktop1000X452?.node
                  ?.altText || 'Category Details'
              }
              width={243}
              height={279}
              loading="eager"
              className="w-full h-full object-cover relative object-right rounded-[21px] ml-auto"
            />
          </picture>
        </div>
      </div>
      {/* Category List Banner */}

      <div className="flex md:items-center justify-between mb-5 md:flex-row flex-col all-pep">
        <h1 className="font-sofiaMedium font-medium md:min-h-0 min-h-[50px] xl:text-[42px] md:text-[32px] text-[32px] text-black">
          All Peptides
        </h1>
        <label className="text-[0px]">Default Sorting</label>
        <select
          id="sort-direction"
          onChange={filterHandler}
          className="bg-bg-grey h-[45px] rounded-[12px] text-[16px] px-3 font-sofiaRegular font-regular focus:outline-none border md:mt-0 mt-2 md:w-[250px] w-full pr-[40px] md:mb-0 mb-3"
        >
          <option value="menu_order">Default Sorting</option>
          <option value="date">Sort by latest</option>
          <option value="price">Sort by price: low to high</option>
          <option value="price-desc">Sort by price: high to low</option>
          <option value="title">Sort by title: A to Z</option>
          <option value="title-desc">Sort by title: Z to A</option>
        </select>
      </div>

      <div className="mt-[-20px] mb-[-20px]">
        <Breadcrumb crumbs={crumbs} />
      </div>

      {loading ? (
        <div className="min-h-[60vh] h-[60vh] flex justify-center items-center">
          <PageLoader />
        </div>
      ) : (
        <section className="text-gray-600 body-font">
          <div className="py-24 mx-auto">
            <div className="flex flex-wrap md:-m-4 -m-3">
              {records?.length > 0 ? (
                records.map((record, idx) => {
                  // Special case for index 3 (first special card)
                  if (idx === 3) {
                    return (
                      <>
                        <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2 md:rounded-[21px] rounded-[21px] cursor-pointer">
                          <LazyLoadImage
                            src={
                              pageData?.allPeptideData?.adv1Image390X543?.node
                                ?.sourceUrl || person
                            }
                            alt="person"
                            width={330}
                            height={460}
                            loading="eager"
                            className="w-full h-full lg:h-[387px] md:h-[481px] cat-adv object-cover lg:rounded-[21px] rounded-[21px]"
                          />
                        </div>
                        <Card key={record.id} record={record} />
                      </>
                    );
                  }

                  // Special case for index 7 (second special card with banner)
                  if (idx === 7) {
                    return (
                      <>
                        <div className="w-full my-4">
                          <div className="bg-primary rounded-[22px] lg:py-2 lg:pl-4 cat-ban-txt gap-2 flex lg:flex-row flex-col justify-start items-center relative">
                            <div
                              className="slg:w-1/3 lg:w-[25%] w-full lg:px-0 lg:pt-0 pt-5 px-4"
                              dangerouslySetInnerHTML={{
                                __html: pageData?.allPeptideData?.advBannerText,
                              }}
                            />
                            <div
                              className="slg:w-1/4 lg:w-[25%] w-full lg:px-0 lg:pt-0 pt-5 px-4"
                              dangerouslySetInnerHTML={{
                                __html:
                                  pageData?.allPeptideData?.advBannerTextList,
                              }}
                            />
                            <div className="slg:w-1/4 lg:w-[25%] w-full">
                              <LazyLoadImage
                                src={
                                  pageData?.allPeptideData?.bannerImage?.node
                                    ?.sourceUrl || proImg
                                }
                                loading="lazy"
                                className="lg:w-[220px] lg:h-[184px] h-[300px] lg:mx-[unset] mx-auto"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2 md:rounded-[21px] rounded-[21px] cursor-pointer">
                          <LazyLoadImage
                            src={
                              pageData?.allPeptideData?.adv2Image390X543?.node
                                ?.sourceUrl || person
                            }
                            alt="person"
                            loading="eager"
                            className="w-full h-full lg:h-[387px] md:h-[481px] cat-adv object-cover lg:rounded-[21px] rounded-[21px]"
                          />
                        </div>
                        <Card key={record.id} record={record} />
                      </>
                    );
                  }

                  // Default case: Render the normal Card for other indices
                  return <Card key={record.id} record={record} />;
                })
              ) : (
                <></> // Empty fragment or message if no records
              )}
            </div>
            <div className="pb-4">
              {totalPages > 1 ? (
                <Pagination
                  page={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default ShopList;
