import { graphql, useStaticQuery } from 'gatsby';
import { uniqueId } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  fetchAllTaxes,
  verifyCoupon,
} from '../../services/woocommerceServices/Checkout';
import {
  getProductByVariantId,
  getProductDetails,
} from '../../services/woocommerceServices/Products';
import { rootReducer } from '../../store';
import {
  applyCoupon,
  checkAndAddToCart,
  removeCoupon,
  setCartLoading,
  userCartItems,
} from '../../store/cartSlice';
import { CartItem, Coupon, ICouponMetaDataDealValue } from '../../types';
import useJWTChecker from '../../utils/Custom-hook/useJWTChecker';
import Button from '../common/Buttons/Button';
import TextInput from '../common/InputFields/TextInput';
import { CloseIconSm, DownArrowIcon } from '../common/Svg';
// import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";

// Function to find the minimum priced products based on quantity
export const findMinPricedProducts = (
  products: CartItem[],
  quantity: number = 1
) => {
  // Sort products by price
  const sortedProducts = [...products].sort(
    (a, b) =>
      Number(a?.variant?.price || a.price) -
      Number(b?.variant?.price || b?.price)
  );

  // Get the two products with the lowest prices
  const minPricedProducts = sortedProducts.slice(0, quantity);

  return minPricedProducts;
};

export const isExistsArray = (
  arr1: (string | number)[],
  arr2: (string | number)[]
) => {
  // Remove duplicates and sort both arrays
  const uniqueArr1 = Array.from(new Set(arr1)).sort(
    (a, b) => Number(a) - Number(b)
  );
  const uniqueArr2 = Array.from(new Set(arr2)).sort(
    (a, b) => Number(a) - Number(b)
  );

  // Check if all elements in arr2 are in arr1
  return uniqueArr2.every((item) => uniqueArr1.includes(item));
};

const OrderDetails = (id?: any) => {
  const dispatch = useDispatch<any>();
  const { userId, email } = useJWTChecker();

  const data = useStaticQuery(graphql`
    query {
      wpPage(isFrontPage: { eq: true }) {
        homePage {
          couponCode
        }
      }
    }
  `);

  const cartItems: CartItem[] = useSelector(
    (state: rootReducer) => state.cart.items
  );
  const subTotal = useSelector((state: rootReducer) => state.cart.subTotal);
  const shippingMethod: any = useSelector(
    (state: rootReducer) => state.cart.shippingMethod
  );
  const coupons: Partial<Coupon[]> = useSelector(
    (state: rootReducer) => state.cart.coupon
  );
  const total: string = useSelector((state: rootReducer) => state.cart.total);

  const [couponCode, setCouponCode] = useState('');
  const [couponRemoval, setCouponRemoval] = useState(false);
  const [loading, setLoading] = useState(false);

  const newArray = Array.from(cartItems, (item) => item.id);

  const [showMore, setShowMore] = useState<string[]>(newArray);

  const handleApplyCoupon = async () => {
    if (!couponCode) {
      toast.warn('Coupon code is required');
      return;
    }
    dispatch(setCartLoading(true));
    setLoading(true);

    const verified_coupon: Coupon[] = await verifyCoupon(
      couponCode,
      coupons,
      userId,
      email,
      true
    );

    if (verified_coupon?.length > 0) {
      const coupon = verified_coupon[0];
      // Adding a product to the cart based on the coupon code
      // console.log(coupon?.meta_data, "<<-- coupon?.meta_data")

      const isDealExist = coupon.meta_data.findIndex(
        (c) => c.key === '_acfw_bogo_deals'
      );

      if (isDealExist > -1) {
        const BOGO_deal = coupon.meta_data.find(
          (i) => i.key === '_acfw_bogo_deals'
        );

        if (
          BOGO_deal &&
          BOGO_deal.value.conditions_type !== 'any-products' &&
          BOGO_deal.value.deals_type !== 'any-products'
        ) {
          const cartItemIds: (string | number)[] = cartItems.map(
            (i) => i.variant.id || i.id
          );
          const dealItemIds: any = BOGO_deal?.value.conditions.map(
            (i: any) => i.product_id
          );
          const isItemMatch = isExistsArray(cartItemIds, dealItemIds);

          if (!isItemMatch) {
            toast.error(
              "Your current cart hasn't met the conditions set for this coupon."
            );
          } else {
            const dealPromises = BOGO_deal.value.deals.map(
              async (deal: ICouponMetaDataDealValue) => {
                // Checking if item is already present in the cart or not
                const productIndex = cartItemIds.findIndex(
                  (i) => i === deal.product_id
                );

                if (productIndex > -1) {
                  console.log('Cart item already exists');
                  const cartItem = cartItems[productIndex];
                  // dispatch(removeFromCart({ productIndex, productId: cartItem.productId }))

                  const obj = {
                    ...cartItem,
                    id: uniqueId(),
                    productId: cartItem.productId,
                    name: cartItem.name,
                    slug: cartItem.slug,
                    price: cartItem.price,
                    thumbnail: cartItem.thumbnail,
                    quantity: 1,
                    variant: cartItem.variant,
                    categories: cartItem.categories,
                    couponDiscount: {
                      ...deal,
                      couponId: coupon.id,
                    },
                  };

                  await dispatch(
                    checkAndAddToCart({
                      item: obj,
                      userId,
                      toast: false,
                    })
                  );
                } else {
                  // Fetch the variant product and its details in parallel
                  const variantProduct: any = await getProductByVariantId(
                    Number(deal.product_id)
                  );
                  const product = await getProductDetails(
                    variantProduct.parent_id
                  );

                  const obj = {
                    id: uniqueId(),
                    productId: product.id,
                    name: product.name,
                    slug: product.slug,
                    price: product.price,
                    thumbnail: product.images[0]?.src,
                    quantity: 1,
                    variant: variantProduct,
                    categories: product.categories,
                    couponDiscount: {
                      ...deal,
                      couponId: coupon.id,
                    },
                  };

                  await dispatch(
                    checkAndAddToCart({
                      item: obj,
                      userId,
                      toast: false,
                    })
                  );
                }
              }
            );

            await Promise.all(dealPromises)
              .then(() => {
                dispatch(applyCoupon(verified_coupon[0]));
              })
              .catch((err: any) => {
                toast.error(err.message);
              });
          }
        } else if (
          BOGO_deal &&
          BOGO_deal.value.conditions_type === 'any-products' &&
          BOGO_deal.value.deals_type === 'any-products'
        ) {
          // const checkCartSize = cartItems.length;
          if (cartItems.length > BOGO_deal.value.conditions.quantity) {
            let minPricedProduct: any = findMinPricedProducts(
              cartItems,
              BOGO_deal.value.deals.quantity
            );
            dispatch(applyCoupon(verified_coupon[0]));
            minPricedProduct.map((product: any) => {
              let obj = {
                ...product,
                couponDiscount: {
                  ...BOGO_deal.value.deals,
                  couponId: coupon.id,
                },
              };
              dispatch(
                checkAndAddToCart({
                  item: obj,
                  userId,
                  toast: false,
                })
              );
            });
          } else {
            toast.error(
              "Your current cart hasn't met the conditions set for this coupon."
            );
          }
        }
      } else {
        dispatch(applyCoupon(verified_coupon[0]));
      }
      setCouponCode('');
    } else {
      setCouponCode('');
    }
    dispatch(setCartLoading(false));
    setLoading(false);
  };

  useEffect(() => {
    dispatch(setCartLoading(true));
    verifyCoupon(data.wpPage.homePage.couponCode, coupons, userId, email)
      .then((verified_coupon: Coupon[]) => {
        if (verified_coupon?.length > 0) {
          if (
            coupons.findIndex((i) => i?.code === verified_coupon?.[0]?.code) < 0
          ) {
            dispatch(applyCoupon(verified_coupon[0]));
          }
        }
        dispatch(setCartLoading(false));
      })
      .catch((err) => {
        console.error(err, '<<-- Error in coupon fetching');
        dispatch(setCartLoading(false));
      });
    dispatch(setCartLoading(false));
  }, [data.wpPage.homePage.couponCode]);

  const handleCouponRemove = (coupon: Coupon | undefined) => {
    if (!coupon) return;
    setCouponRemoval(true);
    dispatch(removeCoupon(coupon));
  };

  useEffect(() => {
    if (couponRemoval) {
      dispatch(
        userCartItems({
          items: cartItems,
        })
      );
      setCouponRemoval(false);
    }
  }, [couponRemoval, cartItems]);

  const fetchAllTaxRates = async () => {
    const taxes = await fetchAllTaxes();
  };

  const calculateTax = (price: number, taxRate: number) => {
    return (price * (taxRate / 100)).toFixed(2);
  };

  const handleShowMore = (
    e: React.MouseEvent<HTMLDivElement>,
    cartId: string,
    index: number
  ) => {
    e.stopPropagation();
    const itemIndex = showMore.findIndex((item) => item === cartId);
    const item = cartItems[index];
    if (item.linkedProducts && item.linkedProducts.length > 0) {
      if (itemIndex > -1) {
        let arr = [...showMore];
        arr.splice(itemIndex, 1);
        setShowMore(arr);
      } else {
        let arr = [...showMore, cartId];
        setShowMore(arr);
      }
    }
  };

  return (
    <div className="lg:w-[40%] md:w-1/2 w-full md:order-2 order-1 mt-[29px] ">
      <div className="w-full md:sticky relative md:top-[100px]  ">
        <h2 className="font-sofiaBold font-bold md:text-[28px] text-[25px] ">
          Your order
        </h2>
        <div className="mt-[22px] p-4 bg-bg-grey rounded-[6px] border">
          <table
            className=" text-left border-separate  w-full order-table"
            style={{ position: 'static', zoom: 1 }}
          >
            <thead>
              <tr>
                <th className="product-name">Product</th>
                <th className="product-total">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {cartItems?.length > 0 &&
                cartItems.map((cartItem, idx) => {
                  let price = Number(
                    cartItem?.variant?.price ?? cartItem?.price
                  );

                  let totalPrice = Number(price) * cartItem.quantity;
                  let discountedPrice = 0.0;

                  // if (
                  // 	cartItem?.couponDiscount &&
                  // 	cartItem?.couponDiscount !== null
                  // ) {
                  // 	const deal = cartItem.couponDiscount;
                  // 	if (deal.discount_type === "percent") {
                  // 		discountedPrice = price * (deal.discount_value / 100) * deal.quantity;
                  // 	} else if (deal.discount_type === "override") {
                  // 		discountedPrice = (price * deal.quantity) - deal.discount_value;
                  // 	} else if (deal.discount_type === "fixed") {
                  // 		discountedPrice = deal.discount_value * deal.quantity;
                  // 	}
                  // 	totalPrice -= discountedPrice;
                  // }
                  totalPrice = Number(totalPrice.toFixed(2));

                  return (
                    <Fragment key={idx}>
                      <tr className="cart_item border-b border-dashed border-[#9F9F9F]">
                        <td className="product-name">
                          <>
                            <div>
                              {cartItem.name}{' '}
                              <span className="product-quantity">
                                ×&nbsp;
                                {cartItem.quantity}
                              </span>
                            </div>
                            <div className="flex gap-2">
                              {cartItem?.variant &&
                                Object.keys(cartItem?.variant).length > 0 &&
                                Object.keys(cartItem?.variant?.attributes).map(
                                  (key: any, index: number) => {
                                    return (
                                      <h3
                                        key={index}
                                        className=" capitalize font-sofiaRegular font-regular text-[#9F9F9F] text-[15px]"
                                      >
                                        {
                                          cartItem?.variant.attributes?.[key]
                                            ?.name
                                        }
                                        :
                                        <span>
                                          {
                                            cartItem?.variant.attributes?.[key]
                                              ?.option
                                          }
                                        </span>
                                      </h3>
                                    );
                                  }
                                )}
                            </div>
                            {cartItem?.linkedProducts &&
                              cartItem.linkedProducts?.length > 0 && (
                                <p
                                  className="font-sofiaRegular font-regular cursor-pointer text-[#9F9F9F] text-[16px] flex items-center gap-1"
                                  onClick={(e) =>
                                    handleShowMore(e, cartItem.id, idx)
                                  }
                                >
                                  <span className="cursor-pointer select-none">{`${
                                    showMore.includes(cartItem.id)
                                      ? 'Hide'
                                      : 'Show'
                                  } ${
                                    cartItem?.linkedProducts?.length
                                  } items`}</span>
                                  <span>
                                    <DownArrowIcon
                                      height={12}
                                      width={12}
                                      className={`${
                                        showMore.includes(cartItem.id)
                                          ? 'rotate-180'
                                          : '-mt-1'
                                      }`}
                                    />
                                  </span>
                                </p>
                              )}
                            <div>
                              {showMore.includes(cartItem.id) && (
                                <div>
                                  {cartItem?.linkedProducts?.map(
                                    (product, index) => {
                                      return (
                                        <div key={index}>
                                          <div className="font-sofiaRegular font-regular text-[15px] cursor-pointer">
                                            {product.name}
                                          </div>
                                          <div className="flex gap-2">
                                            {product?.variant &&
                                              Object.keys(product?.variant)
                                                .length > 0 &&
                                              Object.keys(
                                                product?.variant?.attributes
                                              ).map(
                                                (key: any, index: number) => {
                                                  return (
                                                    <h3
                                                      key={index}
                                                      className=" capitalize font-sofiaRegular font-regular text-[#9F9F9F] text-[15px]"
                                                    >
                                                      {
                                                        product?.variant
                                                          .attributes?.[key]
                                                          ?.name
                                                      }
                                                      :
                                                      <span>
                                                        {' '}
                                                        {
                                                          product?.variant
                                                            .attributes?.[key]
                                                            ?.option
                                                        }
                                                      </span>
                                                    </h3>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </>
                        </td>
                        <td className="product-total">
                          <p className="woocommerce-Price-amount amount flex flex-col items-end">
                            <bdi>
                              <span className="woocommerce-Price-currencySymbol">
                                $
                              </span>
                              {totalPrice.toFixed(2)}
                            </bdi>
                            {parseInt(discountedPrice.toString()) > 0 && (
                              <p className="text-black text-[11px] px-[3px] py-[1px] border border-black rounded-[3px] font-sofiaLight font-regular mb-2">
                                <span>SAVE </span>
                                <span className="woocommerce-Price-currencySymbol">
                                  $
                                </span>
                                {discountedPrice.toFixed(2)}
                              </p>
                            )}
                          </p>
                        </td>
                      </tr>
                    </Fragment>
                  );
                })}
            </tbody>

            <tfoot>
              <tr className="cart-subtotal">
                <th>Subtotal</th>
                <td>
                  <span className="woocommerce-Price-amount amount">
                    <bdi>
                      <span className="woocommerce-Price-currencySymbol">
                        $
                      </span>
                      {subTotal}
                    </bdi>
                  </span>
                </td>
              </tr>
              {/* <tr className="cart-subtotal">
								<th>Tax</th>
								<td>
									<span className="woocommerce-Price-amount amount">
										<bdi>
											<span className="woocommerce-Price-currencySymbol">
												$
											</span>
											{calculateTax(subTotal, 10)}
										</bdi>
									</span>
								</td>
							</tr> */}
              <tr className="woocommerce-shipping-totals shipping">
                <th>Shipping Method</th>
                <td data-title="Shipping">
                  <ul
                    id="shipping_method"
                    className="woocommerce-shipping-methods"
                  >
                    <li>
                      <p>
                        {/* {shippingMethod.method_title || "--"} */}
                        {shippingMethod.method_title
                          ? `${shippingMethod.method_title}${
                              shippingMethod.total && shippingMethod.total > 0.0
                                ? `: $${shippingMethod.total}`
                                : ''
                            }`
                          : '--'}
                      </p>{' '}
                    </li>
                  </ul>
                </td>
              </tr>
              {/* <tr className="woocommerce-shipping-totals shipping">
								<th>Shipping Cost</th>
								<td data-title="Shipping">
									<ul
										id="shipping_method"
										className="woocommerce-shipping-methods"
									>
										<li>
											<p>
												{shippingMethod.total
													? `$${shippingMethod.total}`
													: "--"}
											</p>{" "}
										</li>
									</ul>
								</td>
							</tr> */}
              {coupons &&
                coupons?.length > 0 &&
                coupons.map((coupon) => {
                  return (
                    <tr
                      key={coupon?.code}
                      className="woocommerce-shipping-totals shipping"
                    >
                      <th className="pb-3">
                        <span className="relative">
                          Discount
                          {coupon?.code ? (
                            <span className="relative px-1 pr-[20px] py-1 ml-2 border border-y border-solid rounded-[6px] text-[14px] border-primary bg-[rgba(252,122,120,0.1)]">
                              {coupon.code?.toUpperCase()}
                            </span>
                          ) : (
                            ''
                          )}
                          <span
                            className="absolute ml-[-15px] mt-[7px] text-primary  cursor-pointer  "
                            onClick={() => handleCouponRemove(coupon)}
                          >
                            <CloseIconSm />
                          </span>
                        </span>
                      </th>
                      <td className="pb-3" data-title="Shipping">
                        <ul
                          id="shipping_method"
                          className="woocommerce-shipping-methods"
                        >
                          <li>
                            <p>
                              {coupon?.amount
                                ? `-$${
                                    coupon.discount_type === 'percent'
                                      ? (
                                          Number(subTotal) *
                                          (Number(coupon?.amount) / 100)
                                        ).toFixed(2)
                                      : coupon?.amount
                                  }`
                                : '-$0.00'}
                            </p>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  );
                })}

              <tr className="order-total border-t mt-2 border-dashed border-[#bcbcbc] ">
                <th className="pt-2">Total</th>
                <td className="pt-2">
                  <span className="woocommerce-Price-amount amount font-sofiaSemibold font-semiBold">
                    <bdi>
                      <span className="woocommerce-Price-currencySymbol">
                        $
                      </span>
                      {total}
                    </bdi>
                  </span>{' '}
                </td>
              </tr>
            </tfoot>
          </table>

          <div className="mt-4 mb-5">
            <h3 className=" font-sofiaSemibold font-semiBold text-[17px] ">
              Apply Coupon
            </h3>
            <div className="mt-2 flex justify-between items-center gap-0">
              <TextInput
                placeholder="Enter coupon code"
                className="rounded-l-[6px]  lg:text-[15px] text-[13px] focus:outline-none font-sofiaRegular font-regular  !border-[#bcbcbc] border-r-0 !rounded-r-[0px]"
                name="coupon_code"
                id="coupon_code"
                onChange={(e) => setCouponCode(e.target.value)}
                value={couponCode}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleApplyCoupon();
                  }
                }}
                autoComplete="off"
              />
              <Button
                type="button"
                text="Apply"
                onClick={handleApplyCoupon}
                loading={loading}
                disabled={loading || !couponCode}
                className={`bg-primary cursor-pointer lg:text-[15px] text-[13px] text-white  font-sofiaRegular font-regular  py-2 rounded-r-[6px] rounded-l-[0px] border !border-primary w-[200px] `}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetails;
