import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  CheckStock,
  getVarients,
} from '../../services/woocommerceServices/Products';
import { checkAndAddToCart } from '../../store/cartSlice';
import { Product } from '../../types';
import { Product as ProductType } from '../../types';
import Button from '../common/Buttons/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useJWTChecker from '../../utils/Custom-hook/useJWTChecker';
import {
  getGroupProduct,
  getVariableProduct,
} from '../../services/woocommerceServices/Products';
import { calculatePriceRange, generateUrl } from '../../utils/helper';
import { AppDispatch } from '../../store';
import { uniqueId } from 'lodash';
import { utmParams } from '../../utils/Constants/utcParams';
import Link from '../Link';
import { useLocation } from '@reach/router';

type CardProps = {
  readonly record: Product;
  readonly categoryName?: string;
  readonly categorySlug?: string;
};

const Card = ({ record, categoryName, categorySlug }: CardProps) => {
  const location = useLocation();
  const isType = record.type;
  const { userId } = useJWTChecker();
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [groupedProduct, setGroupedProduct] = useState<ProductType[]>([]);
  const [priceRange, setPriceRange] = useState<any>({});
  // const [price, setPrice] = useState<any>(0);

  const dispatch = useDispatch<AppDispatch>();

  // if(record.price !== '')
  // {
  // 	setPrice(record.price);
  // }
  // console.log('price' , price);
  const handleClick = (
    slug: string,
    productId: string,
    productName: string
  ) => {
    console.log(location.search);

    const to = location.search.replace(
      /([?&])(page=\d+|q=[^&]+)(&|$)/g,
      (match: any, p1: any, p2: any, p3: any) =>
        p1 === '?' && p3 === '&' ? '?' : p1
    );

    navigate(`/products/${slug}` + to, {
      state: {
        productId,
        categoryName,
        categorySlug,
        productName,
      },
    });
  };

  useEffect(() => {
    if (!(record?.grouped_products_nodes || record?.product_variations)) {
      const fetchGroupProducts = async () => {
        const data: any = await getGroupProduct(record?.grouped_products);
        setGroupedProduct(data?.grouped_product);
        // console.log('group product', data);
      };
      const fetchVariableProducts = async () => {
        try {
          if (record.id) {
            const data: any = await getVariableProduct(record?.id);
            setGroupedProduct(data?.varient_product);
            //   console.log('variable product', data);
          }
        } catch (error) {
          // console.error(error);
        }
      };
      if (record && isType === 'grouped') {
        fetchGroupProducts();
      } else if (record && isType === 'variable') {
        fetchVariableProducts();
      }
    } else {
      if (
        record?.grouped_products_nodes &&
        record?.grouped_products_nodes?.length > 0
      ) {
        setGroupedProduct(record?.grouped_products_nodes);
      } else if (
        record?.product_variations &&
        record?.product_variations?.length > 0
      ) {
        setGroupedProduct(record?.product_variations);
      } else {
        setGroupedProduct([]);
      }
    }
  }, [record]);

  // useEffect(() => {
  //   const fetchGroupProducts = async () => {
  //     const data: any = await getGroupProduct(record?.grouped_products);
  //     setGroupedProduct(data?.grouped_product);
  //     // console.log('group product', data);
  //   };
  //   const fetchVariableProducts = async () => {
  //     try {
  //       if (record.id) {
  //         const data: any = await getVariableProduct(record?.id);
  //         setGroupedProduct(data?.varient_product);
  //         //   console.log('variable product', data);
  //       }
  //     } catch (error) {
  //       // console.error(error);
  //     }
  //   };
  //   if (record && isType === "grouped") {
  //     fetchGroupProducts();
  //   } else if (record && isType === "variable") {
  //     fetchVariableProducts();
  //   }
  // }, [record, isType]);

  useEffect(() => {
    if (groupedProduct?.length > 0) {
      const range = calculatePriceRange(groupedProduct);
      setPriceRange(range);
      //   console.log('price range',priceRange);
    }
  }, [groupedProduct]);

  const handelAddtocart = async (product: Product, quantity: number = 1) => {
    const productId = product.wordpress_id ? product.wordpress_id : product.id;
    setDisabled(true);
    setLoading(true);
    const varientDetails = await getVarients(productId);
    const obj = {
      id: uniqueId(),
      productId: productId,
      name: product.name,
      slug: product.slug,
      price: Number(product.price),
      thumbnail: product.images?.[0]?.src,
      variant: varientDetails[0],
      quantity: quantity,
      categories: product.categories,
    };
    try {
      await dispatch(
        checkAndAddToCart({
          item: obj,
          userId: userId,
          quantity: Number(1),
        })
      );
      setLoading(false);
      setDisabled(false);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="lg:w-1/4 sm:w-1/2 w-full md:p-3 p-2 cursor-pointer">
      <div>
        <div className=" rounded-[21px]  md:p-4 p-2 py-3 bg-bg-grey md:hover:bg-white hover:shadow-xl transition-shadow duration-300 catList h-auto bg-helmet ">
          <div
            className="flex items-center h-auto mt-1 justify-between "
            onClick={() => handleClick(record.slug, record.id, record.name)}
          >
            <div className="flex-col flex  gap-1">
              {!record.manage_stock ||
              (record.manage_stock &&
                record.stock_status == 'instock' &&
                record.stock_quantity > 0) ? (
                <>
                  <p className="text-[#225403] font-sofiaMedium font-medium md:text-[14px] text-[12px]">
                    In stock
                  </p>
                </>
              ) : (
                <>
                  <p className="text-primary  font-sofiaMedium font-medium md:text-[14px] text-[12px]">
                    Out of stock
                  </p>
                </>
              )}
            </div>

            {record?.price ? (
              <p className="md:text-[14px] text-[12px] font-sofiaRegular font-regular text-[#383838] leading-[1.2]">
                {/* ${product?.price} - ${varients[0]?.price} */}
                {groupedProduct?.length > 0
                  ? `$${Number(priceRange.min).toFixed(2)} - $${Number(
                      priceRange.max
                    ).toFixed(2)}`
                  : `$${Number(record.price).toFixed(2)}`}
              </p>
            ) : (
              <p className="md:text-[13px] text-[12px] font-sofiaRegular font-regular text-[#383838] leading-[1.2]">
                $0
              </p>
            )}
          </div>
          <Link
            preserveQueryParams={true}
            to={`/products/${record.slug}`}
            state={{
              productId: record.id,
              categoryName,
              categorySlug,
              productName: record.name,
            }}
            className="md:h-[270px] h-[150px]  relative w-full mx-auto  cat-pro"
            // onClick={() => handleClick(record.slug, record.id, record.name)}
          >
            <LazyLoadImage
              // effect="blur"
              alt={record.images?.[0]?.alt || 'TEST'}
              // width={133}
              className="object-contain object-center w-full h-full block"
              src={
                record?.images?.[0]?.src
                  ? record?.images[0]?.src
                  : `${process.env.GATSBY_SITE_URL}/wp-content/uploads/2024/08/no-image-sq.png`
              }
            />
          </Link>
          <h2 className="flex justify-center items-center lg:text-[14px] slg:text-[16px] lg:min-h-[70px] md:min-h-[70px] sm:min-h-[50px] min-h-[40px] md:mt-[-40px] mt-[-10px] text-center md:text-[16px] text-[14px]  font-sofiaRegular font-regular text-black leading-[1.2]  ">
            {record.name}
          </h2>
          <div className="flex justify-between md:mt-[-1px] items-center gap-2">
            {!record.manage_stock ||
            (record.manage_stock &&
              record.stock_status == 'instock' &&
              record.stock_quantity > 0) ? (
              <Link
                disabled={disabled}
                className="bg-black text-white whitespace-nowrap md:px-4 px-1 py-2 rounded-[21px] md:font-sofiaRegular font-regular font-sofiaLight xl:text-[16px]  md:text-[14px] text-[12px] hover:bg-primary transition-colors duration-300 w-1/2 text-center"
                preserveQueryParams={true}
                to={`/products/${record.slug}`}
                state={{
                  productId: record.id,
                  categoryName,
                  categorySlug,
                  productName: record.name,
                }}
              >
                {loading ? 'Adding..' : 'Buy now'}
              </Link>
            ) : (
              <button
                disabled={true}
                className="bg-gray-400 cursor-not-allowed text-white whitespace-nowrap md:px-4 px-1 py-2 rounded-[21px] md:font-sofiaRegular font-regular font-sofiaLight  xl:text-[16px] text-center  md:text-[14px] text-[12px] w-1/2"
              >
                Out of Stock
              </button>
            )}
            <Link
              className="bg-white !border !border-grey-600 rounded-[21px] !text-black md:font-sofiaRegular font-regular font-sofiaLight  xl:text-[16px]  md:text-[14px] text-[12px] md:px-4 !px-1 py-2  hover:bg-primary hover:!text-white transition-colors duration-300 whitespace-nowrap text-center w-1/2"
              aria-label="Learn More to view the product details "
              aria-description="Learn More to view the product details"
              aria-describedby="Learn More to view the product details"
              preserveQueryParams={true}
              to={`/products/${record.slug}`}
              state={{
                productId: record.id,
                categoryName,
                categorySlug,
                productName: record.name,
              }}
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
