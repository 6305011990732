import React, { useEffect, useState } from 'react';
import { HeadFC, navigate, Script } from 'gatsby';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { clearCart } from '../../store/cartSlice';
import { getThankyouDetails } from '../../services/woocommerceServices/Orders';
import Layout from '../../components/layout/Layout';
import GoogleAnalyticsEvent from '../../components/GoogleAnalyticsEvent';
import PageLoader from '../../components/common/Loader/PageLoader';
import Thankyou from '../../components/Checkout/Thankyou';

export default function ThankYouPage(props: any) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState<any>();
  const [woocom, setwoocom] = useState<any>();
  const [dynamicData, setDynamicData] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams(location.search);
    const tokenpay = params.get('tokenpay');
    const idorder = params.get('idorder');
    dispatch(clearCart());
    getThankyouDetails(idorder, tokenpay)
      .then((data) => {
        console.log(data, '<<-- data.woocom');
        setRecords([data.paymentflow]);
        setwoocom([data.woocom]);
        setDynamicData({
          orderId: data.woocom.id,
          orderValue: data.woocom.total,
          shippingTotal: data.woocom.shipping_total,
          totalTax: data.woocom.total_tax,
          items: data.woocom.line_items.map((item: any) => ({
            item_id: item.id,
            item_name: item.name,
            price: item.price,
            quantity: item.quantity,
          })),
        });
      })
      .catch((err) => {
        console.log(err);
        // navigate("/404");
      })
      .finally(() => {
        setLoading(false);
      });

    // ADD KLAVIYO SCRIPT
    setTimeout(() => {
      const script = document.createElement('script');
      script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VvNYhZ`; // Ensure the company ID is correct
      script.type = 'text/javascript';
      script.defer = true;
      document.getElementById('klaviyo-container')?.appendChild(script);
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(['openForm', 'XGzGJW']);
    }, 3000);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'Analytics';
    script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: "12345",
            affiliation: "Online Store",
            value: 1.99,
            currency: "USD",
            items: [
              {
                item_name: "T-Shirt",
                item_id: "12345",
                price: 29.99,
                quantity: 2,
                category: "Apparel"
              }
            ]
          }
        });
      `;

    document.head.appendChild(script);
  }, []);

  return (
    <Layout>
      {dynamicData && <GoogleAnalyticsEvent dynamicData={dynamicData} />}
      {/* <DynamicScriptComponent totalCost={dynamicTotalCost} orderID={dynamicOrderID} /> */}
      {loading ? (
        <div className=" min-h-[60vh] h-[60vh] flex justify-center items-center">
          <PageLoader />
        </div>
      ) : records || woocom ? (
        <Thankyou records={records} woocom={woocom} />
      ) : (
        <div className="container mx-auto flex justify-center items-center h-[60vh]">
          <p className="text-[40px] text-gray-500">No data available</p>
        </div>
      )}
    </Layout>
  );
}
