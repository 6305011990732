export const FILTER_CONTENT: any = [
  {
    label: 'Default sorting',
    value: 'menu_order',
  },
  {
    label: 'Sort by popularity',
    value: 'popularity',
  },
  {
    label: 'Sort by average rating',
    value: 'rating',
  },
  {
    label: 'Sort by latest',
    value: 'date',
  },
  {
    label: 'Sort by price: low to high',
    value: 'price',
  },
  {
    label: 'Sort by price: high to low',
    value: 'price-desc',
  },
];
