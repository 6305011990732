import React, { FC, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import ContactBanner from '../layout/Banner/ContactBanner';
import { Crumb } from '../../types';
import Breadcrumb from '../common/Breadcrumb';
import contactSchema from '../../utils/ValidationSchema/contactValidation';
import TextInput from '../common/InputFields/TextInput';
import TextArea from '../common/InputFields/TextArea';
import PageLoader from '../common/Loader/PageLoader';
import { toast } from 'react-toastify';
import { createZendeskTicket } from '../../services/ZendeskServices';
import { getList, subscribe } from '../../services/KlaviyoServices';

interface props {
  pageData: any;
}

const departments = [
  { label: 'Customer Service', value: 'S4UczK' },
  { label: 'Wholesales', value: 'UdS7Cj' },
  { label: 'Peptides', value: 'VgCG2g' },
  { label: 'Sales', value: 'UD2pmh' },
  // Add more departments as needed
];

const Contactcontent: FC<props> = ({ pageData }) => {
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setCrumbs([{ label: 'Contact', link: null }]);

    // getList()
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      department: '', // Added department field
    },
    validationSchema: contactSchema,
    onSubmit: (values) => {
      const payload = {
        subject: `THIS IS A TICKET FROM ${values.name.toUpperCase()}.`,
        description: values.message.trim(),
        requester: {
          name: values.name.trim(),
          email: values.email.trim(),
          phone: Number(values.phone),
        },
        department: values.department, // Added department to payload
      };
      setLoading(true);
      createZendeskTicket(payload)
        .then(async (result) => {
          formik.resetForm();
          setLoading(false);
          await subscribe(
            values.email.trim(),
            values.name.trim(),
            values.department.trim()
          );
          toast.success(
            'Thanks for contacting us! We will be in touch with you shortly.'
          );
        })
        .catch((error) => {
          // console.log(error);
          setLoading(false);
          toast.error('Something went wrong.');
        });
    },
  });

  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain lg:mt-[10px] mt-[20px]  lg:mb-[80px] mb-[30px]">
      <ContactBanner pageData={pageData} />
      <Breadcrumb crumbs={crumbs} />

      <div className="flex flex-col">
        <div
          className="contact_content md:order-1 order-2 md:mt-0 mt-[30px]"
          dangerouslySetInnerHTML={{ __html: pageData.content }}
        ></div>

        <div className="full md:order-2 order-1">
          <h1 className="form_heading">
            {pageData.contactPageData.contactFormHeading}
          </h1>
          <div className="bg-[#F4F4F4] lg:p-[25px] pb-[25px] px-[15px] pt-[25px] mx-auto xl:w-[908px] md:max-w-[908px] md:w-[90%] w-full rounded-[21px] contact-form">
            {loading && <PageLoader />}
            {!loading && (
              <form
                name="contactForm"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <div className="w-full">
                  <div className="w-full flex flex-col mb-[22px] relative">
                    <TextInput
                      type={'text'}
                      name={'name'}
                      id={'name'}
                      placeholder={'Name'}
                      className={'w-full input'}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className="error-msg !mt-[-25px]">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full flex md:flex-row flex-col md:gap-2 md:mb-[22px]">
                  <div className="md:w-1/2 w-full flex flex-col relative md:mb-0 mb-[22px]">
                    <TextInput
                      type={'email'}
                      name={'email'}
                      id={'email'}
                      className={'w-full input'}
                      placeholder={'Email'}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email ? (
                      <div className="error-msg !mt-[-25px]">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  <div className="md:w-1/2 w-full flex flex-col relative md:mb-0 mb-[22px]">
                    <TextInput
                      type={'number'}
                      name={'phone'}
                      id={'phone'}
                      placeholder={'Phone '}
                      className={'w-full input'}
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.errors.phone && formik.touched.phone ? (
                      <div className="error-msg !mt-[-25px]">
                        {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-full flex flex-col mb-[10px]">
                  <div className="w-full flex flex-col mb-[22px]">
                    <select
                      id="department"
                      name="department"
                      className="w-full input"
                      required
                      aria-placeholder="Department"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.department}
                    >
                      <option value="" label="Select department" />
                      {departments.map((dept) => (
                        <option key={dept.value} value={dept.value}>
                          {dept.label}
                        </option>
                      ))}
                    </select>
                    {formik.errors.department && formik.touched.department ? (
                      <div className="error-msg !mt-[-25px]">
                        {formik.errors.department}
                      </div>
                    ) : null}
                  </div>

                  <TextArea
                    name={'message'}
                    id={'message'}
                    placeholder={'Message'}
                    className={'w-full text-ar'}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                  />
                  {formik.errors.message && formik.touched.message ? (
                    <div className="error-msg !mt-[-25px]">
                      {formik.errors.message}
                    </div>
                  ) : null}
                </div>

                <button
                  type="submit"
                  className="submit-btn bg-black hover:bg-primary w-full py-3 text-white rounded-[35px] my-3"
                >
                  Submit
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactcontent;
