import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import Marquee from 'react-fast-marquee';

interface PencilBannerBox {
  optionOneTitle: string;
  optionTwoTitle: string;
  optionThreeTitle: string;
  optionFourTitle: string;
  optionFiveTitle: string;
  optionOneIcon?: { node?: { sourceUrl: string } };
  optionTwoIcon?: { node?: { sourceUrl: string } };
  optionThreeIcon?: { node?: { sourceUrl: string } };
  optionFourIcon?: { node?: { sourceUrl: string } };
  optionFiveIcon?: { node?: { sourceUrl: string } };
}

interface QueryData {
  allWpPage: {
    nodes: {
      homePage: {
        pencilBannerBox: PencilBannerBox;
      };
    }[];
  };
}

const BannerItem: FC<{
  title: string;
  iconUrl: string;
  fallbackTitle: string;
  fallbackIcon: string;
}> = ({ title, iconUrl, fallbackTitle, fallbackIcon }) => (
  <div className="flex items-center gap-2 cursor-pointer xl:mr-[150px] lg:mr-[60px] mr-[30px]">
    <span>
      <img
        src={iconUrl || fallbackIcon}
        alt={title || fallbackTitle}
        className="md:w-[30px] w-[25px]"
        width={30}
        height={30}
        loading="lazy"
      />
    </span>
    <span className="font-sofiaMedium font-medium lg:text-[19px] text-[17px] md:hover:text-primary">
      {title || fallbackTitle}
    </span>
  </div>
);

const BannerTop: FC = () => {
  const { allWpPage }: QueryData = useStaticQuery(graphql`
    {
      allWpPage(filter: { isFrontPage: { eq: true } }) {
        nodes {
          homePage {
            pencilBannerBox {
              optionOneTitle
              optionTwoTitle
              optionThreeTitle
              optionFourTitle
              optionFiveTitle
              optionOneIcon {
                node {
                  sourceUrl
                }
              }
              optionTwoIcon {
                node {
                  sourceUrl
                }
              }
              optionThreeIcon {
                node {
                  sourceUrl
                }
              }
              optionFourIcon {
                node {
                  sourceUrl
                }
              }
              optionFiveIcon {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `);

  const data: PencilBannerBox | null =
    allWpPage.nodes[0]?.homePage?.pencilBannerBox || null;

  return (
    <div>
      <div className="px-[27px] mt-[0px] lg:mb-[25px] mb-[15px] lg:rounded-[21px] rounded-[12px] bg-bg-grey cursor-pointer">
        <div className="banner-top flex gap-[40px] justify-center lg:h-[87px] h-[58px] min-w-full items-center">
          <Marquee pauseOnHover={true}>
            <BannerItem
              title={data.optionOneTitle}
              iconUrl={data.optionOneIcon?.node?.sourceUrl}
              fallbackTitle="99% Pure Peptides"
              fallbackIcon="https://backend.spartanpeptides.com/wp-content/uploads/2024/10/heart-1.png"
            />
            <BannerItem
              title={data.optionTwoTitle}
              iconUrl={data.optionTwoIcon?.node?.sourceUrl}
              fallbackTitle="Free Shipping"
              fallbackIcon="https://backend.spartanpeptides.com/wp-content/uploads/2024/10/shipping-1.png"
            />
            <BannerItem
              title={data.optionThreeTitle}
              iconUrl={data.optionThreeIcon?.node?.sourceUrl}
              fallbackTitle="Dedicated Services"
              fallbackIcon="https://backend.spartanpeptides.com/wp-content/uploads/2024/10/heart-1.png"
            />
            <BannerItem
              title={data.optionFourTitle}
              iconUrl={data.optionFourIcon?.node?.sourceUrl}
              fallbackTitle="Same Day Shipping"
              fallbackIcon="https://backend.spartanpeptides.com/wp-content/uploads/2024/10/24-7-1.png"
            />
            <BannerItem
              title={data.optionFiveTitle}
              iconUrl={data.optionFiveIcon?.node?.sourceUrl}
              fallbackTitle="Secure Payment Option"
              fallbackIcon="https://backend.spartanpeptides.com/wp-content/uploads/2024/10/credit-card-1.png"
            />
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BannerTop;
