import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import registerSchema from '../../utils/ValidationSchema/registerValidation';
import TextInput from '../common/InputFields/TextInput';
import { createWooCommerceCustomer } from '../../services/woocommerceServices/Customer';
import { toast } from 'react-toastify';
import { getQueryString } from '../../utils/helper';
import { navigate } from 'gatsby';
import { checkNewUser } from '../../services/ForgotPasswordServices';

interface Props {
  goToLogin: Function;
}

const RegisterContent: FC<Props> = ({ goToLogin }) => {
  const [passwordReset, setPasswordReset] = useState(false);

  const formik = useFormik({
    initialValues: {
      remail: '',
    },
    validationSchema: registerSchema,
    onSubmit: async (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      const userPayload = {
        email: values.remail,
      };
      await createWooCommerceCustomer(userPayload)
        .then((res) => {
          //   console.log(res);
          // toast.success("Account created successfully.");
          // const redirectUrl = getQueryString("r");

          // if (!redirectUrl) {
          // 	navigate(-1);
          // } else {
          // 	navigate(`/${redirectUrl}`);
          // }
          const checkUserEmail = async () => {
            try {
              const resp: any = await checkNewUser(userPayload);
              // console.log(userPayload);
              // console.log(resp.data.status);
              if (resp.data.reset_url) {
                setPasswordReset(true);
              }
            } catch (error: any) {
              toast.error(error.msg);
            }
          };
          checkUserEmail();
          resetForm();
        })
        .catch((err) =>
          //   console.error(err, "<<-- error in customer create.")
          toast.error(
            'An account is already registered with your email address.'
          )
        );
    },
  });

  return (
    <>
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] lg:mt-[60px] mt-[20px] lg:mb-[80px] mb-[30px] contain log-ui">
        <h1 className="md:text-center text-left text-[40px]  md:mb-5 mb-2 text-black font-sofiaSemibold font-semiBold ">
          Register
        </h1>
        <div className="bg-[#F4F4F4] lg:py-[25px] pt-[25px]  lg:px-[25px]  mx-auto xl:w-[908px] md:max-w-[908px] md:w-[90%] w-full rounded-[21px]">
          <div className="success-notice md:px-[20px] px-[15px]">
            {passwordReset ? (
              <div className="bg-[#e8f9f4]  border-l-4 border-[#009a6c]  text-black px-4 py-2 shadow-sm flex lg:items-center items-start">
                <div className="lg:mt-0 mt-1">
                  <svg
                    className="fill-current h-5 w-5 text-[#009a6c] mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="22"
                    height="22"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path>
                  </svg>
                </div>
                <p className="font-sofiaMedium font-medium text-[16px]">
                  A link to set a new password has been sent to your email
                  address.
                </p>
              </div>
            ) : null}
          </div>
          <div className="w-full mx-auto md:px-[20px] py-[30px] px-[15px]">
            <form name="registerForm" onSubmit={formik.handleSubmit} noValidate>
              <div className="w-full mt-2">
                <div className="w-full flex flex-col relative">
                  <TextInput
                    type={'email'}
                    name={'remail'}
                    id={'remail'}
                    label={'Email address'}
                    placeholder={'Email address'}
                    className={'w-full input'}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.remail}
                  />
                  {formik.errors.remail && formik?.touched.remail ? (
                    <div className="error-msg">{formik.errors.remail}</div>
                  ) : null}
                </div>
              </div>

              <div className="mt-5 mb-2 font-sofiaRegular font-regular text-[#8D8D8D]">
                <p>
                  Your personal data will be used to support your experience
                  throughout this website, to manage access to your account, and
                  for other purposes described in our{' '}
                  <a
                    className="!text-primary hover:!text-black underline"
                    href="/privacy-policy"
                  >
                    privacy policy
                  </a>
                  .
                </p>
              </div>
              <div>
                <button
                  type="submit"
                  className="submit-btn bg-black hover:bg-primary w-full py-3 text-white rounded-[35px] my-3"
                >
                  Register
                </button>
                <div className="flex justify-center">
                  <a
                    href="#"
                    className="underline w-fit text-center text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18"
                    onClick={(e) => {
                      e.preventDefault;
                      // console.log("first");
                      goToLogin();
                    }}
                  >
                    Sign In
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterContent;
