import axios from 'axios';
import { toast } from 'react-toastify';
export async function subscribe(email: any, name?: any, klavioId?: any) {
  const response: any = await axios.get(`/api/checkProfile?email=${email}`);
  // USER ALREADY EXISTS
  if (response.data.userExists) {
    return { user: true, success: false };
  }

  let data = JSON.stringify({
    data: {
      type: 'subscription',
      attributes: {
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: email,
              first_name: name,
            },
          },
        },
      },
      relationships: {
        list: {
          data: {
            type: 'list',
            // id: process.env.GATSBY_KLAVIYO_LIST_ID,
            id: klavioId.trim(),
          },
        },
      },
    },
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: process.env.GATSBY_KLAVIYO_URL,
    headers: {
      Authorization: `Klaviyo-API-Key ${process.env.GATSBY_KLAVIYO_PK}`,
      revision: '2023-12-06',
      'Content-Type': 'application/json',
    },
    data: data,
  };

  try {
    await axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

    const listData: any = await axios.request(config);
    if (listData.status === 202) {
      return { user: false, success: true };
    } else {
      return { user: false, success: false };
    }
  } catch (error: any) {
    // console.log(error.response.data.errors[0].detail, "<---Klaviyo API Error");
    throw new Error(error.response.data.errors[0].detail);
  }
}

export async function getList() {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://a.klaviyo.com/api/lists/?filter=equals(name,["General","Sales","Wholesales","Peptides","Customer Service"])',
    headers: {
      Authorization: `Klaviyo-API-Key ${process.env.GATSBY_KLAVIYO_PK}`,
      revision: '2024-07-15',
      'Content-Type': 'application/json',
    },
  };
  // console.log(config)
  try {
    const listData: any = await axios.request(config);
    if (listData.status === 202) {
      return {
        listData,
      };
    } else {
      return false;
    }
  } catch (error: any) {
    // console.log(
    //   error.response.data.errors[0].detail,
    //   "<---Klaviyo API get list Error"
    // );
    throw new Error(error.response.data.errors[0].detail);
  }
}

export const aaaaaaaa = async () => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://a.klaviyo.com/api/lists/UnbTxd/profiles/?page[size]=20',
    headers: {
      Authorization: 'Klaviyo-API-Key pk_cbae7251f57913ea37406209286cea0ca0',
      revision: '2023-12-06',
      'Content-Type': 'application/json',
    },
  };

  axios
    .request(config)

    .then((response: { data: any }) => {
      // console.log("data");
      // console.log(JSON.stringify(response));
    })
    .catch((error: any) => {
      // console.log("shjdgjhasgfjhagjf");
      // console.log(error);
    });
};
