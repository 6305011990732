import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
  lusername: Yup.string().required('Username is required'),
  lpassword: Yup.string()
    .required('Password is required')
    .min(4, 'Password must be at least 4 characters long'),
});

export default loginSchema;
