import React, { useCallback, useEffect, useState, FC } from 'react';

interface Props {
  addressDetails: any;
}

const AddressInfoCard: FC<Props> = ({ addressDetails }) => {
  return (
    <div className="bg-white p-4 rounded-b-[12px] mb-6 border-gray-200">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-[18px] text-black font-sofiaSemibold font-semiBold">
          {addressDetails?.address_1 && 'Address'}
        </h3>
      </div>
      <div className="space-y-2">
        <p className="text-[15px] font-sofiaRegular font-regular text-gray-700">
          {addressDetails?.first_name} {addressDetails?.last_name}
        </p>
        <p className=" text-[15px] font-sofiaRegular font-regular text-gray-600">
          {addressDetails?.address_1}
        </p>
        {addressDetails?.address_2 && (
          <p className="text-[15px] font-sofiaRegular font-regular text-gray-600">
            {addressDetails?.address_2}
          </p>
        )}
        <p className=" text-[15px] font-sofiaRegular font-regular text-gray-600">
          {addressDetails?.city && `${addressDetails?.city}, `}
          {addressDetails?.country}
        </p>
        {addressDetails?.state && (
          <p className=" text-[15px] font-sofiaRegular font-regular text-gray-600">
            {addressDetails?.state}
          </p>
        )}
        {addressDetails?.state && (
          <p className=" text-[15px] font-sofiaRegular font-regular text-gray-600">
            {addressDetails?.company}
          </p>
        )}
        {addressDetails?.phone && (
          <p className="text-[15px] font-sofiaRegular font-regular text-gray-600">
            {addressDetails?.phone}
          </p>
        )}
        {addressDetails?.email && (
          <p className="text-[15px] font-sofiaRegular font-regular text-gray-600">
            {addressDetails?.email}
          </p>
        )}
        {addressDetails?.postcode && (
          <p className="text-[15px] font-sofiaRegular font-regular text-gray-600">
            {addressDetails?.postcode}
          </p>
        )}
      </div>
    </div>
  );
};

export default AddressInfoCard;
