import React, { FC, useEffect, useState } from 'react';
import Button from '../common/Buttons/Button';
import { finalPaymentProcess } from '../../utils/helper';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { setPaymentPageLoader } from '../../store/userSlice';
import { toast } from 'react-toastify';

interface Props {
  data: any;
  token: any;
  orderId: any;
}

const GatewayList: FC<Props> = ({ data, token, orderId }) => {
  const dispatch = useDispatch();
  const [iframeKey, setIframeKey] = useState(0);
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [selectedObj, setSelectedObj] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [paymentFailedMsg, setPaymentFailedMsg] = useState('');
  const [errorMsgs, setErrorMsgs] = useState([]);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const handlePaymentChange = (e: any, data: any, index: number) => {
    setSelectedPaymentType(e.target.value);
    setSelectedObj({ ...data, index: index });
    // console.log(selectedObj);
  };

  const payHandeler = async () => {
    setLoading(true);
    dispatch(setPaymentPageLoader(true));
    const finalStatus = await finalPaymentProcess({
      token,
      paymentDesc: selectedObj,
      orderId: Number(orderId),
    });
    localStorage.setItem('paymentCode', selectedObj.payment_code);
    console.log(finalStatus, 'finalStatusfinalStatus');
    // console.log(finalStatus, "====finalStatus");
    if (typeof finalStatus.api_res.data.result == 'boolean') {
      // Get the query parameters from url and pass this through this navigate function
      const currentUrl = new URL(window.location.href);
      const queryParams = new URLSearchParams(currentUrl.search);
      const queryString = queryParams.toString();

      navigate(
        `/thankyou/?tokenpay=${finalStatus.token}&idorder=${orderId}&${queryString}`
      );
      setLoading(false);
      dispatch(setPaymentPageLoader(false));
    } else {
      navigate(finalStatus.api_res.data.result);
      setLoading(false);
      dispatch(setPaymentPageLoader(false));
      // throw new Error("ERROR");
    }
  };

  // Extract all messages dynamically
  const extractMessages = (messagesObj: any) => {
    console.log(messagesObj, '<<-- messagesObj');
    return Object.values(messagesObj).flat();
  };

  useEffect(() => {
    // Event listener handler
    const handleMessage = async (event: any) => {
      const data = event.data;

      // Check the event's origin and token before proceeding
      if (
        event.origin !== 'https://api.pcivault.io' ||
        !data?.token ||
        !selectedObj?.payment_code
      ) {
        return;
      }

      setLoading(true);
      dispatch(setPaymentPageLoader(true));

      try {
        const finalStatus = await finalPaymentProcess({
          token, // Use data.token instead of external 'token'
          paymentDesc: selectedObj,
          orderId: Number(orderId),
          tokenized_data: JSON.stringify(event.data),
        });

        if (finalStatus?.errors?.data?.errors) {
          let allFormMessages: any = [];
          let allPaymentMessages: any = [];
          if (finalStatus?.errors?.data?.errors?.form) {
            allFormMessages = extractMessages(
              finalStatus?.errors?.data?.errors?.form
            );
          }

          if (finalStatus?.errors?.data?.errors?.payment) {
            allPaymentMessages =
              typeof finalStatus?.errors?.data?.errors?.payment === 'string'
                ? [finalStatus?.errors?.data?.errors?.payment]
                : extractMessages(finalStatus?.errors?.data?.errors?.payment);
          }
          setErrorMsgs([...allFormMessages, ...allPaymentMessages]);
        } else {
          localStorage.setItem('vault_token', JSON.stringify(event.data));
          localStorage.setItem('paymentCode', selectedObj.payment_code);
          localStorage.setItem(
            'successMessage',
            selectedObj.success_instruction
          );
          if (typeof finalStatus.api_res.data.result === 'boolean') {
            localStorage.setItem(
              'successMessage',
              selectedObj.success_instruction
            );
            // Get the query parameters from url and pass this through this navigate function
            const currentUrl = new URL(window.location.href);
            const queryParams = new URLSearchParams(currentUrl.search);
            const queryString = queryParams.toString();

            navigate(
              `/thankyou/?tokenpay=${finalStatus.token}&idorder=${orderId}&${queryString}`
            );
          } else {
            navigate(finalStatus.api_res.data.result);
          }
        }
      } catch (err) {
        console.error(err, '<<-- Error in process payment');
        setPaymentFailedMsg('Error in processing payment');
      } finally {
        // Reset loading state after success or error
        // Increment the failed attempts counter
        setFailedAttempts((prev) => {
          const newAttempts = prev + 1;

          if (newAttempts >= 3) {
            // Show toast notification before reloading
            toast.error('Too many failed attempts. Reloading the page...', {
              onClose: () => {
                window.location.reload(); // Reload the page after the toast
              },
              autoClose: 5000, // Toast auto-closes after 3 seconds
            });
          }

          return newAttempts;
        });

        setIframeKey((prev) => prev + 1);
        setLoading(false);
        dispatch(setPaymentPageLoader(false));
      }
    };

    // Add the event listener
    window.addEventListener('message', handleMessage);

    // Cleanup function: Remove event listener on component unmount or when dependencies change
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [selectedObj, orderId]);

  return (
    <div className=" mx-auto py-4">
      <div className="relative flex md:flex-row flex-col md:items-center justify-between mb-4">
        <h2 className="text-2xl font-sofiaBold font-bold ">
          Select a Payment Method
        </h2>

        {!selectedPaymentType && (
          <div className="error-msg !relative !top-0 ">
            Please choose at least one payment method.
          </div>
        )}
      </div>

      {data.map((method: any, index: any) => (
        <div key={index} className="mb-4 border-b pb-4">
          <h3 className="text-xl font-sofiaMedium font-bold mb-4 ">
            {method.type_title}{' '}
          </h3>
          <div className="space-y-2">
            {method.available_payments.map(
              (payment: any, paymentIndex: any) => {
                return (
                  <>
                    <label
                      key={paymentIndex}
                      className="block p-3 bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200"
                    >
                      <input
                        type="radio"
                        name="paymentMethod"
                        value={payment.payment_code}
                        checked={selectedPaymentType === payment.payment_code}
                        onChange={(e) => {
                          handlePaymentChange(e, payment, paymentIndex);
                        }}
                        className="mr-2"
                      />
                      {payment.payment_title} -{' '}
                      {Number(payment.total_payment_amount).toFixed(2)}{' '}
                      {/* Additional charge :{payment.additional_charge.toFixed(2)} */}
                      ({payment.currency})
                      <br />
                    </label>
                    {selectedObj?.payment_code == payment?.payment_code &&
                    selectedObj ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedObj?.payment_description,
                        }}
                      ></div>
                    ) : (
                      ''
                    )}
                    {payment?.form_type === 'cc' &&
                    selectedObj?.iframe_url &&
                    selectedObj.index === paymentIndex ? (
                      <iframe
                        key={iframeKey}
                        src={selectedObj.iframe_url}
                        className="w-full h-[31rem] md:h-[25rem]"
                        title="Payment"
                      ></iframe>
                    ) : (
                      ''
                    )}

                    {/* {paymentFailedMsg &&
                      payment?.form_type === 'cc' &&
                      selectedObj?.iframe_url &&
                      selectedObj.index === paymentIndex && (
                        <p className="text-primary block text-center w-full">
                          {paymentFailedMsg}
                        </p>
                      )} */}

                    {payment?.form_type === 'cc' &&
                      selectedObj?.iframe_url &&
                      selectedObj.index === paymentIndex &&
                      errorMsgs?.length > 0 &&
                      errorMsgs.map((error) => (
                        <p className="text-primary block text-center w-full">
                          {error}
                        </p>
                      ))}
                  </>
                );
              }
            )}
          </div>
          {/* {selectedObj.payment_description} */}
        </div>
      ))}

      {/* <p className="text-gray-600 my-4 font-sofiaRegular">
        Selected Payment: <strong>{selectedPaymentType || "None"}</strong>
      </p> */}

      {!selectedObj?.iframe_url ? (
        <button
          className=" bg-black hover:bg-primary text-white text-[20px] font-sofiaBold font-bold rounded-[40px]   w-full  py-3 px-4 focus:outline-none cursor-pointer "
          type="button"
          onClick={payHandeler}
          disabled={selectedPaymentType ? false : true}
        >
          {!loading ? 'Complete Order' : 'Please wait...'}
        </button>
      ) : null}
    </div>
  );
};

export default GatewayList;
