import axios from 'axios';
import { navigate, PageProps } from 'gatsby';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useJwt } from 'react-jwt';

interface LayoutProps {
  children: ReactNode;
}

const PrivateComponent: React.FC<LayoutProps> = ({ children }) => {
  let token: any;
  try {
    token =
      typeof window !== 'undefined'
        ? JSON.parse(localStorage.getItem('user') as string).token
        : null;
  } catch (error) {
    // console.error(error)
  }

  const { isExpired } = useJwt(token);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    axios
      .post(
        `${process.env.GATSBY_TOKEN_VALIDATOR_URL}`,
        {
          token: token,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          localStorage.removeItem('user');
          navigate('/login');
        }
      })
      .catch((error) => {
        localStorage.removeItem('user');
        navigate('/login');
      });
    setLoading(false);
  }, [token]);

  if (!isExpired) {
    return <>{!loading && children}</>;
  } else {
    navigate('/login');
  }
};

export default PrivateComponent;
