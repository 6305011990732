import React, { FC, useState, useEffect } from 'react';

interface Props {
  id?: string;
  initialQuantity: number;
  setInputValue: Function;
  inputValue: number | string;
}

const ProductDetailsCounter: FC<Props> = ({
  id,
  initialQuantity,
  setInputValue,
  inputValue,
}) => {
  useEffect(() => {
    setInputValue(initialQuantity);
  }, [initialQuantity]);

  const increment = () => {
    setInputValue((prev: any) => (prev === '' ? 1 : Number(prev) + 1));
  };

  const decrement = () => {
    if (Number(inputValue) > 1) {
      setInputValue(Number(inputValue) - 1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      // Allow only numeric input
      setInputValue(value);
    }
  };

  const handleInputBlur = () => {
    if (inputValue === '' || Number(inputValue) < 1) {
      setInputValue(1); // Default to 1 if empty or invalid
    }
  };

  return (
    <div className="flex items-center  w-fit">
      <button
        className="bg-bg-grey text-black w-[40px] lg:h-[54px] h-[38px] flex items-center justify-center focus:outline-none md:hover:bg-black md:hover:text-white lg:rounded-l-[54px] rounded-l-[12px] border-l border-y transition-all"
        onClick={decrement}
      >
        -
      </button>
      <label htmlFor={id || `product_details_counters`} className="text-[0px]">
        Counter
      </label>
      <input
        id={id || `product_details_counters`}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        className="bg-white font-sofiaLight font-light text-[13px] border borde-[#81878F] text-black w-[65px] focus:outline-none lg:h-[54px] h-[38px] flex items-center justify-center text-center"
      />
      <button
        className="bg-bg-grey text-black w-[40px] lg:h-[54px] h-[38px] flex items-center justify-center  focus:outline-none md:hover:bg-black md:hover:text-white lg:rounded-r-[54px] rounded-r-[12px]  border-r border-y  transition-all"
        onClick={increment}
      >
        +
      </button>
    </div>
  );
};

export default ProductDetailsCounter;
