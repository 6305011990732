'use-client';
import React, { useEffect, useState } from 'react';
import { getSearchResults } from '../../utils/helper';
import Layout from '../../components/layout/Layout';
import PageLoader from '../../components/common/Loader/PageLoader';
import SearchBlock from '../../components/common/SearchBlock';
import { graphql, navigate, Script } from 'gatsby';
import { searchProduct } from '../../services/woocommerceServices/Products';
import ProductCard from '../../components/Products/ProductCard';
import BannerTop from '../../components/layout/Banner/BannerTop';
import Clickmagic from '../../components/common/ClickMagic/Clickmagic';
import { useLocation } from '@reach/router';

export default function index({ data }: any) {
  console.log(data, '<<-- data');
  const [records, setRecords] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(true);
  // const [pageData, setPageData] = useState<any>(null); // Added state for pageData
  const pageData = data.allWpPage.edges[0].node.homePage;
  const params = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );
  const parameter1: string | null = params.get('q');
  useEffect(() => {
    if (parameter1) {
      // {**** BACKUP API CALL FOR GLOBAL SEARCH START  *****}
      // getSearchResults(parameter1)
      // 	.then((results) => {
      // 		console.log(results);
      // 		setLoading(false);
      // 		if (results.length > 0) {
      // 			setRecords(results);
      // 		} else {
      // 			setRecords(false);
      // 		}
      // 	})
      // 	.catch((error) => {
      // 		console.error("Error fetching search results:", error);
      // 		setLoading(false);
      // 	});
      // {**** BACKUP API CALL FOR GLOBAL SEARCH END *****}

      setLoading(true);
      searchProduct(parameter1)
        .then((results: any) => {
          // console.log('result',results);
          setLoading(false);
          setRecords(results.data);
        })
        .catch((error) => {
          // console.error("Error fetching search results:", error);
          setLoading(false);
        });
    } else {
      navigate('/');
    }
  }, [parameter1]);

  return (
    <>
      <Layout>
        <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain md:mb-[80px] mb-[50px]">
          <BannerTop />
          <h1 className="text-[32px] font-sofiaMedium font-medium text-black mt-[-20px] ">
            Search for result: {parameter1}
          </h1>
          {loading && (
            <div className=" min-h-[60vh] h-[60vh] flex justify-center items-center">
              {' '}
              <PageLoader />{' '}
            </div>
          )}
          {!loading && records.length == 0 && (
            <div className="mt-10 mb-10">
              <div
                className="bg-[#f6e0df] border-l-4 border-[#ba322f] text-black px-4 py-2 shadow-sm flex lg:items-center items-start"
                role="alert"
              >
                <div className="flex items-center">
                  <div className="py-1">
                    <svg
                      className="fill-current h-5 w-5 text-primary mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-sofiaMedium font-medium">
                      Sorry, but nothing matched your search criteria. Please
                      try again with some different keywords.
                    </p>
                    <p className="text-sm"></p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading && records.length > 0 && (
            <div className="flex flex-wrap justify-start -m-2 mt-10 ">
              {records.map((product: any, index: any) => (
                <div
                  key={product.id}
                  className="lg:w-1/4 sm:w-1/2 w-full md:w-1/2  cursor-pointer search-list"
                >
                  <ProductCard
                    key={index}
                    product={product}
                    isShowAddToCartBtn={false}
                    isSearchPage={true}
                  />
                </div>
              ))}
              {/* {records.map((data: any, index: any) => {
							return (
								<>
								<SearchBlock
										data={{
											id: data.id,
											subtype: data.subtype,
											title: data.title,
											type: data.type,
											url: data.url,
										}}
									/> 
                </>
							);
						})} */}
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}

export const query = graphql`
  query HeaderQuery {
    allWpPage(filter: { isFrontPage: { eq: true } }) {
      edges {
        node {
          homePage {
            pencilBannerBox {
              optionFiveTitle
              optionFourTitle
              optionThreeTitle
              optionTwoTitle
              optionOneTitle
              optionOneIcon {
                node {
                  sourceUrl
                }
              }
              optionTwoIcon {
                node {
                  sourceUrl
                }
              }
              optionThreeIcon {
                node {
                  sourceUrl
                }
              }
              optionFourIcon {
                node {
                  sourceUrl
                }
              }
              optionFiveIcon {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = (props: any) => {
  const location = useLocation();
  return (
    <>
      <link
        rel="canonical"
        href={`https://spartanpeptides.com/${location.pathname}`}
      ></link>
      <meta name="robots" content={'index, follow'} />
    </>
  );
};
