import * as React from 'react';
import { Link, HeadFC, PageProps } from 'gatsby';

import Layout from '../components/layout/Layout';
import error from '../assets/images/error.png';
import cat from '../assets/images/error-2.png';

const pageStyles = {
  color: '#232129',
  padding: '96px',
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: '#8A6534',
  padding: 4,
  backgroundColor: '#FFF4DB',
  fontSize: '1.25rem',
  borderRadius: 4,
};

const NotFoundPage: React.FC<PageProps> = () => {
  return (
    <Layout>
      <div className="flex flex-col justify-center items-center md:h-full h-full">
        <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px]  lg:py-[100px] md:py-[50px] py-24 items-center flex justify-center flex-col-reverse lg:flex-row   lg:gap-28 gap-7">
          <div className="lg:pt-24 md:pt-[50px]  w-full xl:w-1/2 relative  lg:pb-[30px] ">
            <div className="relative mb-[50px] z-10">
              <h1 className="my-2 text-black font-sofiaBold font-bold md:text-[30px] text-[25px] ">
                Looks like you've found the doorway to the great nothing
              </h1>
              <p className="my-2 mb-8 text-gray-500 font-sofiaLight font-light">
                Sorry about that! Please visit our hompage to get where you need
                to go.
              </p>
              <Link
                to="/"
                className="bg-primary  text-white px-5 py-3 rounded-[21px] mt-[30px] mb-[50px] font-sofiaRegular font-regular md:text-[16px] text-[12px] hover:bg-black transition-colors duration-300 "
              >
                Go to Home!
              </Link>
            </div>
            <div className="absolute top-[30px] z-0">
              <img
                src={error}
                alt="Spartan Peptide 404  error"
                width={300}
                height={279}
                className=" w-[65%] h-full object-contain "
              ></img>
            </div>
          </div>
          <div>
            <img
              src={cat}
              alt=""
              width={243}
              height={279}
              className=" w-full h-full object-contain relative"
            ></img>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

// export const Head: HeadFC = () => <title>Not found</title>
