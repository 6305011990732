import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  productId: string;
}

const StarReview: FC<Props> = ({ productId }) => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://staticw2.yotpo.com/32WCW8rg0gbhiSy1IHEZjRuKH0UynB7Va83EdmlP/widget.js";
  //   script.async = false;
  //   script.defer= true;
  //   script.type = "text/javascript";
  //   document.body.appendChild(script);
  // }, []);

  return (
    <>
      {/* <Helmet>
        <script
          src="https://staticw2.yotpo.com/u2xWtaR1xTImF32f978yKOwX90zv9z38bsDzLpWp/widget.js"
          async
          defer
          type="text/javascript"
        />
      </Helmet> */}
      <div
        className="yotpo bottomLine"
        data-product-id={productId}
        data-url="The url to the page where the product is url escaped"
      ></div>
    </>
  );
};

export default StarReview;
