import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { uniqueId } from 'lodash';
import { checkAndAddToCart } from '../store/cartSlice';
import { useDispatch } from 'react-redux';
import useJWTChecker from '../utils/Custom-hook/useJWTChecker';
import TruncatedHtml from './common/TruncatedHtml/TruncatedHtml';
import { useLocation } from '@reach/router';
const UpsellModal = (props: any) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const data = useStaticQuery(graphql`
    query {
      allWpPage(filter: { isFrontPage: { eq: true } }) {
        edges {
          node {
            homePage {
              ageVerificationModal {
                logo {
                  node {
                    sourceUrl
                  }
                }
                modalBody
                termsAndConditions
              }
            }
          }
        }
      }
    }
  `);

  const { ageVerificationModal } = data.allWpPage.edges[0].node.homePage;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const dispatch = useDispatch<any>();
  const { userId, email } = useJWTChecker();

  const handleClick = async (prod: any) => {
    setIsProcessing(true);
    const parentId = prod.acf.main_product[0].ID;
    // FOR SIMPLE PRODUCT ADD TO CART
    if (prod.type == 'simple') {
      const obj = {
        id: uniqueId(),
        productId: prod.id,
        name: prod.name,
        slug: prod.slug,
        price: prod.price,
        thumbnail: prod.images[0]?.src,
        quantity: 1,
        parentId: parentId ?? null,
        categories: prod.categories,
      };

      await dispatch(checkAndAddToCart({ item: obj, userId, toast: true }));
    }
    // FOR OTHER PRODUCT REDIRECT TO DETAILS PAGE
    else {
      navigate(`/products/${prod.slug}` + location.search, {
        state: {
          productId: prod.wordpress_id,
          categoryName: prod.categories?.[0].name,
          categorySlug: prod.categories?.[0].slug,
          productName: prod?.name,
        },
      });
    }
    setIsProcessing(false);

    if (props.productList.length == 1) {
      setIsOpen(false);
    }
  };

  if (!isOpen) return null;

  return (
    isOpen && (
      <>
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white w-[95%] xl:max-w-[950px] lg:max-w-[65%] md:max-w-[90%] max-w-full rounded-[21px] relative shadow-lg">
            <button
              className="absolute top-4 right-4 text-black hover:text-gray-700 w-[30px] h-[30px] border-2 rounded-full border-black"
              onClick={handleClose}
            >
              &#10005;
            </button>
            <div className="text-center md:pt-8 pt-4 ">
              <div className="mx-auto w-[200px] mb-4">
                <img
                  src={ageVerificationModal.logo.node.sourceUrl}
                  alt="Spartan Peptides"
                  width={300}
                  height={270}
                  className="mx-auto w-full object-contain mb-4"
                />
              </div>
              <h2 className="text-primary font-sofiaSemibold font-semibold xl:text-[30px] lg:text-[28px] text-[25px]">
                Special Offer
              </h2>
              {props.productList && (
                <div className="upsell-details">
                  <Carousel
                    responsive={responsive}
                    infinite={props.productList.length > 2}
                  >
                    {props?.productList?.map((data: any) => {
                      return (
                        //   {/* <h3 className="xl:text-[50px] lg:text-[40px] text-[25px] font-sofiaSemibold font-semibold">
                        //     20% Off Your Second Bottle!
                        //   </h3>
                        //   <p className="text-black font-sofiaRegular lg:max-w-[75%] mx-auto mt-2 xl:text-[17px] lg:text-[15px] text-[14px]">
                        //     For a limited time, when you purchase any bottle, you’ll receive
                        //     20% off your second bottle. Don’t miss out on this great deal to
                        //     stock up on your favorite peptides.
                        //   </p> */}

                        // </Carousel>
                        <div className="upsell-slider">
                          {/* <div
                            dangerouslySetInnerHTML={{
                              __html:
                                data.acf.product_upsell_discount_information,
                            }}
                          ></div> */}

                          <TruncatedHtml
                            showReadMore={false}
                            wordCount={208}
                            html={data.acf.product_upsell_discount_information}
                          />

                          <div className="flex md:flex-row flex-col justify-between items-center ">
                            <div className="relative lg:w-1/4 md:w-1/2 w-full mx-auto">
                              <img
                                src={data.images?.[0].src}
                                alt={data.images?.[0].name}
                                className="w-full object-contain md:h-[270px] h-[150px] relative"
                              />
                              {data.acf.discount_percentage && (
                                <div className="twenty-bg flex justify-center items-center">
                                  <span className="relative md:text-[38px] text-[30px] rotate-[-20deg] text-center text-white font-bold leading-[1] mt-[-10px] w-[78px]">
                                    {data.acf.discount_percentage}% OFF
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="text-left lg:w-[35%] md:w-1/2 w-full mx-auto md:mt-5 mt-[0px] lg:mr-0 md:mr-3 mr-0 ">
                              <h4 className="font-bold font-sofiaRegular xl:text-[20px] lg:text-[18px] text-[17px] mb-3">
                                If you take this offer you will get: {data.name}
                              </h4>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    data.acf
                                      .product_upsell_discount_benefits_information,
                                }}
                              ></div>
                              {/* {data.product_upsell_discount_information} */}
                              {/* <ul className="text-black font-sofiaRegular flex flex-col gap-3 upsell-list">
                                    <li>Consistent Supply</li>
                                    <li>Significant Savings</li>
                                    <li>Variety and Flexibility</li>
                                    <li>Convenience</li>
                                  </ul> */}

                              <div className="flex flex-col lg:justify-center md:justify-start justify-center mt-4 gap-4">
                                <button
                                  className="bg-primary rounded-[30px] text-[18px] py-3 px-9 font-sofiaRegular text-white lg:mx-auto md:mx-0 mx-auto w-fit hover:bg-black transition"
                                  onClick={() => {
                                    handleClick(data);
                                  }}
                                  disabled={isProcessing}
                                >
                                  {isProcessing
                                    ? 'Processing..'
                                    : 'YES, I WANT TO SAVE!'}
                                </button>
                                <button
                                  className="text-black font-sofiaRegular hover:text-primary underline text-[14px] font-medium transition lg:text-center md:text-left text-center"
                                  onClick={handleClose}
                                >
                                  NO THANKS, I’M WILLING TO PAY MORE
                                </button>
                              </div>
                            </div>
                            <div className="w-1/4 relative lg:block hidden pr-4">
                              <img
                                src={data?.images?.[1]?.src}
                                alt={data?.images?.[1]?.name}
                                className="w-full object-contain md:h-[270px] h-[150px]"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              )}
            </div>
            <div className="bg-[#F7F7F7] py-4 rounded-b-[21px]">
              <p className="text-center font-sofiaRegular text-[11px] text-[#383838] md:px-0 px-3">
                Terms and conditions apply. Offer valid for a limited time only.
                Discount applies to the lower-priced item. Cannot be combined
                with other offers.
              </p>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default UpsellModal;
