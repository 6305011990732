import { combineReducers, configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import userReducer from './userSlice';

const combineReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
});

const store = configureStore({
  reducer: combineReducer,
});

export default store;

export type rootReducer = ReturnType<typeof combineReducer>;
export type AppDispatch = typeof store.dispatch;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
