import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import TextInput from '../common/InputFields/TextInput';
import accountDeatilsSchema from '../../utils/ValidationSchema/accountDetaiilsValidation';
import Button from '../common/Buttons/Button';
import { CloseIcon } from '../common/Svg';

interface Props {
  data: any;
  buttonLoading: any;
  onPressSaveBtn: Function;
  onPressCancelBtn: Function;
}

const EditAccountDetails: FC<Props> = ({
  data,
  buttonLoading,
  onPressSaveBtn,
  onPressCancelBtn,
}) => {
  useEffect(() => {
    if (data) {
      formik.setValues({
        firstname: data?.first_name,
        lastname: data?.last_name,
        email: data?.email,
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
    },
    validationSchema: accountDeatilsSchema,
    onSubmit: async (values) => {
      // console.log("values", values);

      onPressSaveBtn(values);
    },
  });

  return (
    <div className=" ">
      <div className="max-w-full bg-white border rounded-[12px] overflow-hidden">
        <div className="mb-2 py-3 px-3 flex justify-between items-center bg-bg-grey border-b">
          <h2 className="text-[20px] font-sofiaSemibold font-semiBold text-black  text-center">
            Edit Account Details
          </h2>
          <button
            onClick={() => {
              onPressCancelBtn();
            }}
            className="flex items-center w-8 h-8 text-gray-500 hover:text-gray-700 text-xl"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="md:p-6 p-3">
          <form
            name="editAccountDetailsForm"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="flex md:flex-row flex-col md:gap-4 ">
              <div className="mb-4 md:w-1/2 w-full">
                <div className="w-full flex flex-col font-sofiaRegular font-regular relative">
                  <TextInput
                    type={'text'}
                    name={'firstname'}
                    id={'firstname'}
                    label={'First Name'}
                    placeholder={'Enter first name'}
                    className={'w-full input mt-4'}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                  />
                  {formik.errors.firstname && formik?.touched.firstname ? (
                    <div className="error-msg md:!top-[2px] !top-[4px]">
                      {formik.errors.firstname}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mb-4 md:w-1/2 w-full">
                <div className="w-full flex flex-col font-sofiaRegular font-regular relative">
                  <TextInput
                    type={'text'}
                    name={'lastname'}
                    id={'lastname'}
                    label={'Last Name'}
                    placeholder={'Enter last name '}
                    className={'w-full input mt-4'}
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                  />
                  {formik.errors.lastname && formik?.touched.lastname ? (
                    <div className="error-msg md:!top-[2px] !top-[4px]">
                      {formik.errors.lastname}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="w-full flex flex-col font-sofiaRegular font-regular relative">
                <TextInput
                  type={'text'}
                  name={'email'}
                  id={'email'}
                  label={'Email Address'}
                  placeholder={'Enter email address '}
                  className={'w-full input mt-4'}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik?.touched.email ? (
                  <div className="error-msg md:!top-[2px] !top-[4px]">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex md:flex-row flex-col gap-4 justify-end mt-8 mb-6">
              <Button
                type="submit"
                text="Save"
                className="submit-btn hover:bg-black bg-primary text-[20px] font-sofiaMedium font-medium w-full  py-3 text-white rounded-[35px]  transition-all ease-in "
                loading={buttonLoading}
                disabled={buttonLoading}
              />

              <Button
                onClick={() => {
                  onPressCancelBtn();
                }}
                type="submit"
                text="Cancel"
                className="submit-btn !bg-bg-grey hover:!bg-black text-[20px] font-sofiaMedium font-medium w-full  py-3 !text-black hover:!text-white border rounded-[35px]  transition-all ease-in"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EditAccountDetails;
