import React from 'react';
import Load from '../../../assets/images/load.gif';

export default function PageLoader() {
  return (
    <div className="w-full md:h-[100%] h-[100%] flex justify-center items-center">
      <img
        src={Load}
        className="object-contain w-[70px] h-[70px] "
        alt="Loading"
        width={70}
        height={70}
      />
    </div>
  );
}
