import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './NewsLetter.css';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import TextInput from '../common/InputFields/TextInput';
import { subscribe } from '../../services/KlaviyoServices';
import { toast } from 'react-toastify';

const NewsLetter = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name is required')
        .min(2, 'Name must be at least 2 characters long'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const email = values.email;
      const name = values.name;
      try {
        setLoading(true);
        const result = await subscribe(email, name, 'S27Kry');
        if (!result.user && result.success) {
          toast.success('You are subscribed. ');
        } else if (result.user && !result.success) {
          throw new Error('You are already subscribed. ');
        } else {
          throw new Error('Something went wrong.');
        }
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        toast.error(error.message);
      }
      resetForm();
    },
  });

  return (
    <LazyLoadComponent>
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain md:mb-[100px] mb-[50px] contain">
        <div className="news-bg w-full !bg-bg-grey rounded-[21px] lg:px-[50px] md:py-[60px] py-[30px] px-[15px]">
          <h2>Get 10% off your first order!</h2>
          <p className="md:mt-[30px] mt-[10px] ">
            Register your Email and you will be added to our Email Mailing List
            and you will receive a 10% off Voucher to use on your next order.
            (Valid Once per Customer)
          </p>
          <p>
            Don’t worry, we hate spam too – that’s why we send out emails only
            to showcase new items or announce Special Offers and Launch Drops
            for this specific website. You have the option to unsubscribe at any
            moment.
          </p>
          <form
            name="newsLetterForm"
            onSubmit={formik.handleSubmit}
            className="md:pt-[40px] pt-[10px] w-full flex lg:flex-row flex-col gap-4"
          >
            <div className="lg:w-[80%] md:w-full flex xmd:flex-row flex-col gap-4">
              {/* Name Field */}
              <div className="md:w-1/2 w-full flex flex-col xmd:mb-[22px] relative">
                <TextInput
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  className="w-full input"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="error-msg !mt-[-25px]">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              {/* Email Field */}
              <div className="md:w-1/2 w-full flex flex-col xmd:mb-[22px] relative">
                <TextInput
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  className="w-full input"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error-msg !mt-[-25px]">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            {/* Submit Button */}
            <div className="lg:w-[20%] md:w-full">
              <button
                type="submit"
                className="submit-btn bg-black hover:bg-primary w-full py-3 text-white rounded-[35px]"
              >
                {loading ? 'Processing' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </LazyLoadComponent>
  );
};

export default NewsLetter;
