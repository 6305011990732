import * as Yup from 'yup';

const accountDeatilsSchema = Yup.object().shape({
  firstname: Yup.string().required('Firstname is required'),
  lastname: Yup.string().required('Lastname is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Please enter email'),
});

export default accountDeatilsSchema;
