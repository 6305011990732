import React, { FC, useEffect, useRef } from 'react';
import 'react-multi-carousel/lib/styles.css';
import ProductCard from './ProductCard';
import { useSwipeable } from 'react-swipeable';
import { graphql, useStaticQuery } from 'gatsby';
import { getGroupProduct } from '../../services/woocommerceServices/Products';

interface Props {
  productList: any;
  title: string;
  isShowAddToCartBtn?: boolean;
}

const ProductCarousel: FC<Props> = ({
  productList,
  title = 'Product list',
  isShowAddToCartBtn,
}) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);

  // Swipe handlers for touch events
  const handlers = useSwipeable({
    onSwipedLeft: () => scrollRight(),
    onSwipedRight: () => scrollLeft(),
  });

  // Scroll handlers
  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -200,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 200,
        behavior: 'smooth',
      });
    }
  };

  // Handle mouse wheel event
  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    if (carouselRef.current) {
      const scrollAmount = e.deltaY; // Change scroll amount sensitivity as needed
      carouselRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="md:my-[70px] my-[50px] ">
      <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain">
        <h2 className="xl:text-[65px] lg:text-[55px] text-[32px] font-sofiaMedium font-medium mb-6">
          <span>{title.split(' ')[0]}</span>
          <span className="text-primary mx-2">{title.split(' ')[1]}</span>
          <span>{title.split(' ')[2]}</span>
        </h2>
      </div>
      <div
        {...handlers}
        className="w-full overflow-x-auto scroll-smooth contain"
        ref={carouselRef}
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
      >
        <div className="flex space-x-4 justify-start pro-slider">
          {productList.map((product: any, index: any) => (
            <ProductCard
              key={index}
              product={product}
              isShowAddToCartBtn={isShowAddToCartBtn}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
