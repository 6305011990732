import { createSlice } from '@reduxjs/toolkit';
import { Customer } from '../types';

const initialState = {
  userDetails: {},
  countries: [],
  states: [],
  toggle: false,
  paymentPageLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserData: (state, action) => {
      state.userDetails = action.payload;
    },

    setCountryList: (state, action) => {
      state.countries = action.payload;
    },

    setStateList: (state, action) => {
      state.states = action.payload;
    },

    removeAll: (state) => {
      state.userDetails = {};
      state.countries = [];
      state.states = [];
    },
    stateToggler: (state, action) => {
      state.toggle = action.payload;
    },

    setPaymentPageLoader: (state, action) => {
      state.paymentPageLoading = action.payload;
    },
  },
});

export const {
  addUserData,
  setCountryList,
  setStateList,
  removeAll,
  stateToggler,
  setPaymentPageLoader,
} = userSlice.actions;

export default userSlice.reducer;
