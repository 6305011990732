import React from 'react';
import { Provider } from 'react-redux';
import store from './src/store';

import './src/styles/global.css';
import 'react-toastify/dist/ReactToastify.css';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Provider store={store}>{element}</Provider>
    </>
  );
};

export const onRouteUpdate = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
};

// export const onRouteUpdate = ({ location, prevLocation }) => {
// 	// Check if the current URL starts with /productss/
// 	if (location.pathname.startsWith("/productss/")) {
// 		// Extract the dynamic part of the URL (the slug)
// 		const slug = location.pathname.replace("/productss/", "");

// 		// Redirect to the new URL format
// 		const newPath = `/products/${slug}`;

// 		// Use window.location to perform a client-side redirect
// 		window.location.replace(newPath);
// 	}
// 	if(location.pathname.startsWith("/products-category/")) {
// 		// Extract the dynamic part of the URL (the slug)
// 		const slug = location.pathname.replace("/products-category/", "");

// 		// Redirect to the new URL format
// 		const newPath = `/product-category/${slug}`;

// 		// Use window.location to perform a client-side redirect
// 		window.location.replace(newPath);
// 	}
// };
