import * as Yup from 'yup';

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(5, 'Password should be at least 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),

  confirmpassword: Yup.string()
    .required('Please re-enter confirm password')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

export default forgotPasswordSchema;
