import React, { FC, Fragment, useEffect, useState } from 'react';
import Layout from '../../components/layout/Layout';
import parse from 'html-react-parser';
import BlogCard from '../../components/blog/BlogCard';
import { graphql, Link } from 'gatsby';
import Seo from '../../components/Seo';
import Breadcrumb from '../../components/common/Breadcrumb';
import Pagination from '../../components/common/Pagination/Pagination';
import { useLocation } from '@reach/router';

const ITEMS_PER_PAGE = 6; // Adjust this value as needed

const blogCategoryTemplate: FC<any> = ({ data, pageContext, location }) => {
  // console.log(pageContext, location, "<<== page context");
  const { name, description, posts } = pageContext;
  const [currentPage, setCurrentPage] = useState(1);

  const categoryList: any[] = data?.allWpCategory?.nodes;
  const crumbsData = [
    { label: pageContext.name, link: `/blog-details/${pageContext.slug}` },
  ];

  const totalPages = Math.ceil(posts.nodes.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const postList = posts.nodes.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('size')) {
      navigate(``);
    }
  }, []);

  return (
    <Layout>
      <section className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] contain pt-[10px] pb-[40px]">
        <Breadcrumb crumbs={crumbsData} />
        <h1 className="xl:text-[45px] lg:text-[35px] text-[25px] mt-[-15px] font-sofiaMedium font-medium text-black ml-[-4px] md:min-h-[40px] min-h-[35px]">
          {name}
        </h1>
        <div className="blog-text mt-[5px] md:mb-[10px] mb-[5px] w-full ">
          {description ? parse(description) : <></>}
        </div>

        {categoryList?.length > 0 ? (
          <div className="categoryList md:mb-5 mb-3">
            <ul className="flex flex-wrap md:gap-3 gap-1">
              <Link
                to={`/blog`}
                className={`bg-[#F2F2F2] flex md:py-3 py-2 md:px-5 px-3 rounded-[21px] font-sofiaRegular font-normal text-[12px]`}
              >
                All articles
              </Link>
              {categoryList?.length > 0 ? (
                categoryList.map((category: any, index: any) => (
                  <Link
                    to={`/blog-category/${category.slug}`}
                    className={` ${
                      category?.name === pageContext?.name
                        ? 'bg-black text-white'
                        : 'bg-[#F2F2F2]'
                    } flex md:py-3 py-2 md:px-5 px-3 rounded-[21px] font-sofiaRegular font-normal text-[12px]`}
                    key={category?.id}
                  >
                    {category?.name}
                  </Link>
                ))
              ) : (
                <></>
              )}
            </ul>
          </div>
        ) : (
          <></>
        )}
        <div className="flex flex-wrap -m-2 md:gap-y-[25px] gap-y-[12px]">
          {postList?.length > 0 ? (
            postList.map((bloginfo: any, index: any) => (
              <Fragment key={bloginfo.id}>
                <BlogCard blog={bloginfo} />
              </Fragment>
            ))
          ) : (
            <></>
          )}
        </div>
      </section>
      {postList?.length > 0 && totalPages > 1 ? (
        <div className="pb-4">
          <Pagination
            page={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default blogCategoryTemplate;

export const query = graphql`
  query {
    allWpCategory(filter: { count: { gt: 0 } }) {
      nodes {
        id
        name
        slug
      }
    }
  }
`;

export const Head = (props: any) => {
  let { data, pageContext } = props;
  const seoInfo = pageContext.seo.fullHead;
  const parseHtml: any = parse(seoInfo);
  const [canonicalIsPresent, setCanonicalIsPresent] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const canonicalIndex = parseHtml.findIndex(
      (item: any) => item?.props?.rel === 'canonical'
    );

    if (canonicalIndex > -1) {
      setCanonicalIsPresent(true);
    } else {
      setCanonicalIsPresent(false);
    }
    console.log(canonicalIsPresent, 'canonicalIsPresent');
  }, [parseHtml]);

  const index =
    parseHtml &&
    parseHtml?.length > 0 &&
    parseHtml.findIndex(
      (item: any) => item?.props?.type === 'application/ld+json'
    );

  if (index > -1) {
    parseHtml.splice(index, 1);
  }

  // Retrieve current location from @reach/router's useLocation
  const location = useLocation();
  const canonicalUrl = `https://spartanpeptides.com${location.pathname}`;

  return (
    <>
      {parseHtml}
      <Seo info={seoInfo} />
      {/* <link rel="canonical" href={canonicalUrl} data-gatsby-head="true"></link> */}
    </>
  );
};
